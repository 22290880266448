@import "../../assets/styles/variables.scss";

#loadingOverlay{
  position:fixed;
  z-index:9999;
  display:flex;
  align-items:center;
  justify-content:center;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  background-color: $primaryBackground;
  background-image: url('../../assets/images/layout/desktop-loading.webp');
  background-size:cover;
  background-repeat:no-repeat;
  background-position:bottom;

  &.hide{
    display:none;
  }
  #loadingIndicatorContainer{
    width:60vh;
    height:60vh;
    font-family: 'Pragati Narrow';
    font-size:3em;
    font-weight:bold;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    color: $lightText;
    #loadingIndicatorWrapper{

      width:20vh;
      height:20vh;
      margin-bottom:40px;
      .MuiCircularProgress-root{
        &.MuiCircularProgress-colorPrimary{
          color:$reversedLinkText;
        }
      }
    }
  }
}

@media screen and (max-width:750px){
  #loadingOverlay{
    background-image: url('../../assets/images/layout/mobile-loading.webp');
    #loadingIndicatorContainer{
      font-size:2em;
    }
  }
}