
@import '../../../assets/styles/variables.scss';
  
  .dialog-template-select-hero {
    .MuiDialogContent-root{
      div.avatar_hex{
        position:absolute;
        right:-20%;
        top:-10%;
        width: 500px;
        height:500px;
        background-size:80%;
        background-repeat:no-repeat;
        background-position:right;
        opacity:.35;
        z-index : 0;
        transform: rotate(-33deg);
      }
      p{
        font-family: 'Spartan', Helvetica, Arial, sans-serif;
        font-size: .9em;
        margin-bottom: 2em;
      }
      #elementalHeader{
        text-align:center;
        font-family: 'Pathway Gothic One', sans-serif;
        font-size: 1.5em;
        color: $mediumText;
        margin:1.5em 0;
        p{
          opacity:.35;
        }
      }
      #characterElementContainer{
        
        margin: 2em 0em;
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        text-align:center;
        div.element-type{
          font-family: 'Pathway Gothic One', sans-serif;
          font-size: 1em;
        }
        .element-wrapper{
          display:flex;
          flex-direction:column;
          &.element-wrapper-disabled{
     
          }
          .hexagon{
            align-self:center;
          }
          .element-description{
            font-size:.8em;
            line-height:1;
            font-family: 'Pragati Narrow', Helvetica, Arial, sans-serif;
          }
        }
      }
    }
    .MuiPaper-root{
      overflow:hidden;
    }
    .MuiInputBase-root{
      width:50%;
      display:block;
      #avatarNameInput{
        font-family: 'Pragati Narrow', Helvetica, Arial, sans-serif;
        font-size: 1.3em;
  
      }
    }

    #selectAvatarModal{
      .MuiInputBase-root{
        margin: 30px auto;
      }
    }
    #messageWrapper{
      min-height:40px;
      width:100%;
      #heroNameHelperText, #checkingMessage{
        text-align:center;
        padding:10px;
      }
    }
    #heroNameInputContainer{
      position:relative;
      text-align: center;
      z-index:1000;
      input{
        padding: 5px;
      }
    }
  
  }




  

  
  



@media screen and (max-width:500px){
    .dialog-template-select-hero {
    
      .MuiDialog-paperScrollPaper{
        height:100%;
        max-height:initial;
      }
      .MuiDialogContent-root{
        overflow-y:scroll;
        #elementalHeader{
          margin-top:initial;
        }
        #characterElementContainer{
          margin:1em 0;
        }
        p{
          font-size: .6em;
          text-align: justify;
        }
      }
      .MuiDialogActions-root{
        position:absolute;
        bottom:0px;right:0px;
      }
  
    }
}

@media screen and (max-height:700px){
  .dialog-template-select-hero {
    .MuiDialogContent-root{
      flex:initial;
      #characterElementContainer{
        margin: 0em;
      }
    }
    // .MuiDialog-paperScrollPaper{
    //   height:100%;
    //   max-height:initial;
    // }
    .MuiDialogActions-root{
      position:relative;
    }
  }
  #messageWrapper{
    font-size:.8em;
  }
}