// DAWN
@mixin background-dawn{
  background: -moz-linear-gradient(top, #ffbf61 0%, #fee3ba 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #ffbf61 0%,#fee3ba 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #ffbf61 0%,#fee3ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin background-dawn-reverse{
  background: -moz-linear-gradient(bottom, #ffbf61 0%, #fee3ba 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #ffbf61 0%,#fee3ba 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #ffbf61 0%,#fee3ba 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// Day
@mixin background-day{
  background: -moz-linear-gradient(top, #47b9e6 0%, #e8f8ff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #47b9e6 0%,#e8f8ff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #47b9e6 0%,#e8f8ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin background-day-reverse{
  background: -moz-linear-gradient(bottom, #47b9e6 0%, #e8f8ff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #47b9e6 0%,#e8f8ff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #47b9e6 0%,#e8f8ff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// DUSK
@mixin background-dusk{
  background: -moz-linear-gradient(top, #4e5481 0%, #fd5e53 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #4e5481 0%,#fd5e53 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #4e5481 0%,#fd5e53 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin background-dusk-reverse{
  background: -moz-linear-gradient(bottom, #4e5481 0%, #fd5e53 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #4e5481 0%,#fd5e53 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #4e5481 0%,#fd5e53 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// NIGHT
@mixin background-night{
  background: -moz-linear-gradient(top, #061928 0%, #164d78 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #061928 0%,#164d78 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #061928 0%,#164d78 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin background-night-reverse{
  background: -moz-linear-gradient(bottom, #061928 0%, #164d78 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(bottom, #061928 0%,#164d78 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to top, #061928 0%,#164d78 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

// ATTACK OF THE ELEMENTS
@mixin background-elemental-event-mobile{
  background-image: url('../../assets/images/layout/mobile-home-elemental-background.webp');
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color:#333533;
}
@mixin background-elemental-event-desktop{
  background-image: url('../../assets/images/layout/desktop-home-elemental-background.webp');
  background-size: auto 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color:#333533;
}