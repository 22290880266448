
@import '../../../../assets/styles/variables.scss';

.dialog-template-headless{
  &.battle-report-win{
    .MuiPaper-rounded{
      background : radial-gradient(circle at center, yellow 10%,transparent 66%);
    }
  }
  &.battle-report-loss{
    .MuiPaper-rounded{
      background : radial-gradient(circle at center, #1d0e29 10%,transparent 66%);
      #winner, #loser{
        color : #a0a0a0;
      }
      #outcomeStatement{
        color : #c2c2c2;
      }
    }
  }
  &.dialog-template-battle-report{
    &.battle-report-win{
      .MuiPaper-rounded{
        background : radial-gradient(circle at center, yellow 10%,transparent 66%);
      }
    }
    &.battle-report-loss{
      .MuiPaper-rounded{
        background : radial-gradient(circle at center, #1d0e29 10%,transparent 66%);
        #winner, #loser{
          color : #a0a0a0;
        }
        #outcomeStatement{
          color : #c2c2c2;
        }
      }
    }
    .MuiPaper-rounded{ 
      background-image: url('../../../../assets/images/layout/newspaper-background.webp');
      background-position: center;
      background-color: $primaryBackground;
      #contentSection{
        overflow: hidden;
      }
    }
    
  }
  &.dialog-template-battle-report-detail{
    &.battle-report-loss{
      .title{
        background-color: rgba(232, 237, 223,.7);
      }
    }
    .bd-section{
      display:flex;
      font-size: 1em;
      color: $darkText;
      font-family: $narrow;
      > div{
        width:100%;
        
        p{
          background-color: $primaryBackground;
          padding:5px;
          border: solid 1px $mediumText;
          margin-top: 0px;
          strong{
            font-family: $narrowHeadline;
          }
          span{
            font-family: $narrow;
          }
        }
      }
      svg{
        color : $brandHighlight;
        margin-right:10px;
      }
      .title{
        font-family:$narrowHeadline;
        font-size: 1.5em;
        color: $mediumText;
      }
    }
    #bdRow1{
      display: flex;
      #bdScenario, #bdBattlePrep{
        flex : 1;
      }
    }
    table{
      background-color: $primaryBackground;
      border-collapse: collapse;
      width: 100%;
      margin-bottom:20px;
      font-size: .9em;
      text-align:center;
      td,th{
        border: solid 1px $mediumText;
        padding: 0px 3px;
        &.background-highlight{
          background-color: $highlight;
        }
      }
      th div{
        line-height:1em;
        margin-bottom: 5px;
        &#heroFoeSubtext{
          font-weight:initial;
        }
        &.th-subtext{
          font-weight:initial;
          color: gray;
        }
      }
      td:first-of-type{
        text-align:left;
        min-width:40px;
      }
      td{
        &.health-remaining-td{
          padding:0px;
        }
        .health-remaining-container{
          display:flex;
          span{
            width:50%;
            display:block;
          }
        }
      }
    }
  }
  .MuiPaper-rounded{ 
    min-height: 400px;
    background-position:center;
    background-size: cover;
    &:after{
      content : '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0%;
      left: 0%;
      z-index: -1;
      background-image: url('../../../../assets/images/layout/newspaper-background.webp');
      background-position: center;
      background-color: $primaryBackground;
    }
  }
  .content-wrapper{
    height:100%;
    .contender-image{
      height:60%;
      position: absolute;

      &#winnerImage{
        left: 10px;
        top: 10px;
      }
      &#loserImage{
        right: 10px;
        bottom: 50px;
      }
      &.contender-image-spirits:not(.contender-image-Shadow-Self){
        height: 70%
      }
      &.contender-image-spirits{
        opacity: .85;
      }
    }
    #contentSection{
      display:flex;
      flex-direction:column;
      align-items: center;
      justify-content:center;
      font-weight:bold;
      height: 100%;
      #title{
        font-family: $newsHeadline;
        text-align:center;
        width:90%;
        z-index: 1;
        margin-top: 10vh;
        #winner{
          font-size:10vmin;
          line-height:7vmin;
        }
        #outcome{
          font-size:4vmin;
          color: $newsprintTextLight;
        }
        #loser{
          font-size:5vmin;
          line-height:5vmin;
        }
        .tie{
          font-size:7vmin;
          line-height:7vmin;
        }
      }
      #outcomeStatement{
        font-weight: normal;
        opacity: .5;
        font-family: $newsHeadline;
        margin-bottom: 10px;
      }
      #loot{
        font-family: $narrowHeadline;
        position: absolute;
        bottom: 10vh;
        left: 8px;
        z-index: 1000;
        .battle-gain-item{
          cursor : pointer;
          color : $mediumText;
          line-height:1.5em;
          strong{
            color: $reversedLinkText;
            text-decoration: underline;
            //text-shadow: 2px 2px 2px black;
     
          }
        }
        span{
          display:block;
          color: $mediumText;
          line-height:1.5em;
          strong{
            color: $highlight;
            //text-shadow: 2px 2px 2px black;
           
          }
        }
      }
      #avatar{
        position:relative;
      }
    }
  }

}

@media screen and (max-width:599px){
  .dialog-template-headless{
    .content-wrapper{
      #contentSection{
        #title{
          #winner, #loser{
            line-height:10vmin;
          }
        }
      }
    }
    .MuiPaper-rounded{
      
      .MuiDialogContent-root{
        min-height: 60vh;
        display: flex;
        justify-content: center;
        align-items: center;
        #contentSection{
          margin-top: 8vh;
          #title{
            margin-top: initial;
          }
          #loot{
            bottom: 12vh;
          }
        }
        .contender-image{
          height: 40%;
          &.contender-image-spirits:not(.contender-image-Shadow-Self){
            height: 50%;
          }
        }
        .bd-section{
          table{
            font-size: .8em;
          }
        }

        #title{
          width: 100% !important;
          #winner{
            font-size: 14vw !important;
          }
          .tie, #loser{
            font-size: 12vw !important;
          }
          #outcome{
            font-size : 7vw !important;
          }
        }


      }
    }
    &.dialog-template-battle-report-detail{
      .MuiDialogContent-root{
        align-items: flex-start;
      }
      
      .MuiDialogContent-root{
        align-items: top !important;
      }
  
      .MuiDialogActions-spacing{
        display:none;
      }
      .MuiDialog-container{
        margin-top:20px;
      }
    }
    &.dialog-template-battle-report{
      .MuiDialog-container{
        margin-top:20px;
      }
    }
  }
}