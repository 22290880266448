@import "../../assets/styles/variables.scss";
@import "../../assets/styles/animations.scss";

#welcomePage {
 position: relative;
 justify-content: center;
 text-align: center;
 min-height: 700px;
 width: 100%;
 background-image: url("../../assets/images/layout/desktop-loading.webp");
 background-size: cover;
 background-repeat: no-repeat;
 background-position-x: center;
 background-position-y: bottom;
 #welcomePageContent {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: $reversedText;

  #linkToAbout {
   padding: 20px 40px 60px;

   a {
    color: white;
    font-weight: bold;
    &:hover {
     color: $brandHighlight;
    }
   }
  }
  #loginLink {
   font-family: $headline;
   cursor: pointer;
   margin: 3em 0 4em;
   border-bottom: solid 4px white;
   strong {
    font-size: 3em;
    &:hover {
     color: $highlight;
    }
   }
  }
  h1 {
   font-size: 10em;
   margin: 0px;
   font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
   text-shadow: 5px 5px $darkText;
   color: $brandHighlight;
  }
  h2 {
   margin-top: -1em;
   color: $darkText;
   font-family: "Pathway Gothic One", sans-serif;
   font-size: 2em;
  }
  #aboutContent {
   width: 35%;
   text-align: center;
   font-family: $narrowHeadline;
   font-size: 1.2em;

   strong {
    color: $brandHighlight;
   }
  }
  #mainCta {
   margin-top: 20px;
   h2.brand-highlight {
    color: $brandHighlight !important;
    text-transform: uppercase;
    margin-top: 10px;
   }
   #appAndPlayButtons {
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    img {
     height: 60px;
     &:hover {
      box-shadow: 0 0 10px 5px #fff, /* inner white */ 0 0 20px 15px #d4af37, /* middle gold */ 0 0 200px 90px ivory; /* outer ivory */
     }
    }
    #googlePlay {
     height: 61px;
     margin-left: 25px;
    }
   }
  }
  #onboardSteps {
   font-family: $narrowHeadline;
   background-color: rgba(0, 0, 0, 0.4);
   padding: 20px;
   border-radius: 8px;
   margin-bottom: 3vh;
   .cta-initiation-title {
    font-size: 1.5em;
    font-family: $headline;
   }
   ol {
    text-align: left;
   }
  }
 }

 #welcomePageOverlay {
  z-index: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: $darkBackground;
 }
 #pickChampionButton {
  position: relative;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: float 6s ease-in-out infinite;
  animation-delay: 2s;
  margin-bottom: 1em;
  margin-top: 1em;
  &:hover {
   animation: shake 0.5s;
   animation-iteration-count: infinite;
  }
  &:after {
   content: "";
   position: absolute;
   left: 50%;
   top: 50%;
   border-radius: 50%;
   z-index: 1;
   background-color: #fff;
   box-shadow: 0 0 60px 30px #fff, /* inner white */ 0 0 100px 60px #d4af37, /* middle gold */ 0 0 200px 90px ivory; /* outer ivory */
  }
 }
 #titleLogo img {
  margin-top: 20px;
  margin-bottom: 50px;
  max-width: 450px;
  width: 100%;
 }
 p {
  margin-top: 0px;
  margin-bottom: 1.5em;
  line-height: 1.5em;
 }
}
#simpleLoginButtons {
 position: relative;
 flex-direction: column;
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 10;
 width: 100%;
 height: 100%;
 min-height: 50vh;
 div {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  #buttonSpacer {
   height: 58px;
   line-height: 58px;
  }
  button {
   width: 100%;
   font-size: 3em;
   height: 60px;
   line-height: 50px;
  }
 }
}
@media screen and (max-width: 750px) {
 #welcomePage {
  background-image: url("../../assets/images/layout/mobile-loading.webp");
  #welcomePageContent {
   #loginLink {
   }
   h1 {
    font-size: 8em;
    margin: 10vmin 0vmin;
   }
   h2 {
    margin-top: -2em;
   }
  }
  #aboutContent {
   width: 95% !important;
   text-align: center !important;
  }
  #appAndPlayButtons {
   img {
    height: initial !important;
   }
   #googlePlay {
    height: 41px !important;
    margin-left: 15px;
   }
  }
  #pickChampionButton {
   margin-bottom: 1em;
   margin-top: 1em;
  }
 }
}
