@import '../../assets/styles/variables.scss';
@import '../../assets/styles/background-mixins.scss';
@import '../../assets/styles/animations.scss';

#home{
  position: relative;
  height:100vh;
  width: 100vw;
  .background{
    width:100%;
    height:100%;
    position:absolute;
    top: 0px;
    left: 0px;
    background-repeat: no-repeat;
    z-index: 0;
  }
 
  &.home-section-activities{
    min-height:0;
    .table-container{
      overflow:scroll;
    }
  }

  #avatarSection{
    height:calc(82vh);
    position:relative;
    #topConsole{
      padding:10px;
      .hexagon-outside{
        height:9vh !important;
      }
      img{
        position: relative;
        z-index: 20;
        width: 150px;
        margin-top: -17px;
        margin-left: -20px;
      }
      #progressBars{
        position: absolute;
        top: 15px;
        width: calc(100% - 40px);
        left: 40px;

        > div{
          z-index:1;
          &+ .hud-bar-text{
            color: $reversedTextAlt;
            font-family: $narrow;
            font-size: 5vmin;
            position:absolute;
            z-index:10;
            line-height:5.5vmin;
            opacity:.7;
            svg{
              vertical-align: middle;
              display: inline-block;
              line-height: 1rem;
              margin-top:-4px;
              opacity:.6;
            }
            &#healthBarText{
              top:1vh;
              left: calc(70% - 25vw);
            }
            &#experienceBarText{
              top:6.5vh;
              left: calc(50% - 25vw);
              font-size: 4vmin;
              line-height:4vmin;
              .xp-label{
                font-family: $headline;
                opacity:.5;
              }
            }
          }
          &:last-of-type{
            margin-top:-2px;
            z-index:0;
          }
        }
        
      }
    }
    #avatarQuickFacts{
      color: $reversedTextAlt;
      font-family: $narrowHeadline;
      font-size: 6vmin;
      //margin-top: 8vh;
      > div{
        margin-bottom: 10px;
      }
      .avatar-name{
        font-family: $headline;
        font-size: 18vmin;
        line-height:10vmin;
      }
      .avatar-title{
        font-size: 1rem;
        margin-bottom: 0;
        margin-bottom: -.5vh;
        svg{
          margin-right : 7px;
        }
      }
      .avatar-status{
        .albedo-level{
          color : $aether;
          padding: 5px;
          border-bottom: solid 3px $aether;
        }
      }
      .avatar-training-sessions{
        //margin-bottom:15px;
        span{
        }
      }
      .avatar-last-upgrade{
        span{

        }
      }
    }
    #avatarContainer{
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      //top: calc(50% - 35vw);
      bottom: 10vw;
      transform: translatey(0px);
      height:80vw;
      width:100%;
      #avatar{
        position:relative;
        animation: float 6s ease-in-out infinite;
        img#avatarImage{
          width:70vw;
          max-width:500px;
        }
        &.knocked-out{
          filter: contrast(150%) saturate(.2) brightness(20%);
          animation: none;
        }
        &.recovering{
          filter: saturate(.5) brightness(70%);
        }
      }
    }
    #petContainer{
      position: absolute;
      bottom: 10vw;
      .item-pet{
        position:absolute;
        width: 25vw;
        max-width:150px;
        left:0px;
        bottom:-10vw;
        z-index:1;
      }
    }
  }
  #consoleSection{
    //display:none;
    position:fixed;
    bottom: 0px;
    background-color: $mediumBackground;
    width:100%;
    border-top: solid 1px $highlight;
    z-index: 1;
    #versionNumber{
      font-size: 3vw;
      font-family: $narrowHeadline;
      left:10px;
      top:-2em;
    }
    #battleReportLink{
      right:10px;
      top:-10vh !important;
      position:absolute;
      text-align:center;
      svg{
        font-size: 4em;
        color: $darkText;
        position: relative;
        display:block;
        height: .7em;
        cursor: pointer;
        &.shake {
          -webkit-animation: shake 1.25s linear both;
          animation: shake 1.25s linear both;
        }

      }
      
      &:hover{
        svg{
          color: $highlight;
        }
        &:after{
          color: $highlight;
        }
      }
      &:before{
        content: '';
        position:absolute;
        left:50%;
        top:65%;
        border-radius: 50%;
        z-index:-1;
        background-color: #fff;
        box-shadow:
        0 0 30px 15px #fff,  /* inner white */
        0 0 50px 30px #D4AF37, /* middle gold */
        0 0 150px 45px ivory; /* outer ivory */
      }
      &:after{
        cursor: pointer;
        line-height:.4em;
        font-family: $narrow;
        font-weight: heavy;
        content : 'Battle Report'
      }
    }
    #theHerosPath{
      right:10px;
      top:-13vh !important;
      position: absolute;
      font-size: .8em;
    }
    .arrow-up {
      position: absolute;
      top:-15.2vmin;
      left: calc(50% - 15vmin);
      width: 0; 
      border-left: 15vmin solid transparent;
      border-right: 15vmin solid transparent;
      border-bottom: 15vmin solid $mediumBackground;
      z-index: 0;
      cursor: pointer;
      &:hover{
          .inner-arrow{
            //border-bottom: 13vw solid $reversedTextAlt;
          }
          .innermost-arrow{
            border-bottom: 7vmin solid $reversedTextAlt !important;
          }
      }
      .inner-arrow{
        position: absolute;
        top:1vh;
        left: calc(50% - 11vmin );
        border-left: 11vmin  solid transparent;
        border-right: 11vmin  solid transparent;
        border-bottom:11vmin solid transparent;
        .innermost-arrow{
          position: absolute;
          top: 3.5vmin ;
          left: calc(50% - 7vmin );
          border-left: 7vmin  solid transparent;
          border-right: 7vmin  solid transparent;
          border-bottom: 7vmin  solid $highlight;
        }
      }
    }
    #actionButtons{
      width:100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index:10;
      position : relative;
      background-color: $mediumText;
      .action-button{
        box-shadow: 3px 5px 5px $darkBackground inset,
        -3px -5px 5px $darkBackground inset;
        padding:15px;
        width:50%;
        button{
          font-weight:bold;
          font-size:7vmin;
          height: 15vmin;
        }
      }

    }
    #secondaryButtons{
      display:flex;
      .action-button{
        flex: 1;
        padding: 5px;
        button{
          width:100%;
          height: 10vmin;
        }
      }
      &.hidden{
        display:none;
      }
    }
    #stats{
      &.hidden{
        display:none;
      }
      #avatarPrimaryAttributes, #avatarElementalAttributes{
        display:flex;
        flex-direction:row;

        color: $reversedTextAlt;
        font-family: $headline;
        box-shadow: 3px 5px 5px $darkBackground inset,
          -3px -5px 5px $darkBackground inset;
        padding:10px 5px;
        > div{
          display:flex;
          flex-direction:row;
          align-items: center;
          justify-content:center;
          flex: 1;
          border-right: solid 1px $darkBackground;
          padding:.5em;
          text-align:center;
          min-height: 10vmin;
          &:last-of-type{
            border-right:none;
          }
          svg{
            
          }
          span{
            display:block;
            line-height: .9em;
            font-size:6min;
          }
          strong{

            font-size:10vmin;
            display:block;
            line-height: .9em;
            color: $attributeHighlight;
          }
        }
      }
      #avatarPrimaryAttributes{
        svg{
          padding-right:.3em;
          font-size:10vmin;
        }
      }
      #avatarElementalAttributes{
        > div{
          svg{
            font-size:6vw;
          }
        }
      }
    }
  }
  // Darker text for lighter color schemes
  &.background-day, &.background-dawn{
    #avatarQuickFacts{
      color: $mediumText !important;
      strong.highlight{
        color: $darkText;
      }
    }
    #versionNumber{
      color: $mediumText;
    }
    .settings-button svg{
      color: $darkText;
    }
  }
}

  // Gradient backgrounds
  .background-dawn{
    @include background-dawn;
  }
  .background-day{
    @include background-day;
  }
  .background-dusk{
    @include background-dusk;
  }
  .background-night{
    @include background-night;
  }
  .background-elemental-event{
    @include background-elemental-event-mobile;
  }
  
@media screen and (min-width:1025px){
  #home{

    #avatarSection{
      #topConsole{
        #progressBars{
          > div{
            &+ .hud-bar-text{
              color: $reversedTextAlt;
              font-family: $narrow;
              font-size: 4vh;
              position:absolute;
              z-index:10;
              line-height:5.5vmin;
              opacity:.7;
              svg{
                vertical-align: middle;
                //display: inline-block;
                line-height: 1rem;
                margin-top:-4px;
                opacity:.6;
              }
              &#healthBarText{
                top:0vh;
                left: calc(70% - 19.2vw);
              }
              &#experienceBarText{
                top:5.7vh;
                left: calc(50% - 17vw);
                font-size: 3vh;
  
                .xp-label{
                  font-family: $headline;
                  opacity:.5;
                }
              }
            }
            &:last-of-type{
              margin-top:-2px;
              z-index:0;
            }
          }
          
        }
      }
      #avatarQuickFacts{
        color: $reversedTextAlt;
        font-family: $narrowHeadline;
        font-size: 3vh;
        > div{
          margin-bottom: 1vh;
        }
        .avatar-name{
          font-family: $headline;
          font-size: 8vh;
        }
        
      }
    }

    #consoleSection{
      #versionNumber{
        font-size: 2vh;
        font-family: $narrowHeadline;
        left:10px;
        top:-2em;
      }
      #battleReportLink{
        position: absolute;
        right:10px;
        top:-2em;
      }
      #theHerosPath{
        position: absolute;
        right:10px;
        top:-8em;
        font-size: .8em;
      }
      .arrow-up {
        position: absolute;
        top:-8vmin;
        left: calc(50% - 8vh);
        width: 0; 
        border-left: 8vh solid transparent;
        border-right: 8vh solid transparent;
        border-bottom: 8vh solid $mediumBackground;
        z-index: 0;
        cursor: pointer;
        &:hover{
            .inner-arrow{
              //border-bottom: 13vw solid $reversedTextAlt;
            }
            .innermost-arrow{
              border-bottom: 7vmin solid $reversedTextAlt !important;
            }
        }
        .inner-arrow{
          position: absolute;
          top:1vh;
          left: calc(50% - 11vh );
          border-left: 11vh  solid transparent;
          border-right: 11vh  solid transparent;
          border-bottom:11vh solid transparent;
          .innermost-arrow{
            position: absolute;
            top: 3.5vh ;
            left: calc(50% - 7vh );
            border-left: 7vh  solid transparent;
            border-right: 7vh  solid transparent;
            border-bottom: 7vh  solid $highlight;
          }
        }
      }
      #actionButtons{
        width:100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        z-index:10;
        position : relative;
        background-color: $mediumText;
        .action-button{
          box-shadow: 3px 5px 5px $darkBackground inset,
          -3px -5px 5px $darkBackground inset;
          padding:15px;
          width:50%;
          button{
            font-weight:bold;
            font-size:4.5vh;
            height: 10vh;
          }
          
        }
      }
      #stats{
        #avatarPrimaryAttributes, #avatarElementalAttributes{
          > div{
            display:flex;
            flex-direction:row;
            align-items: center;
            justify-content:center;
            flex: 1;
            border-right: solid 1px $darkBackground;
            padding:.5em;
            text-align:center;
            min-height: 7vh;
            &:last-of-type{
              border-right:none;
            }
            svg{
              
            }
            span{
              display:block;
              line-height: .9em;
              font-size:3vh;
            }
            strong{
  
              font-size:7vh;
              display:block;
              line-height: .9em;
              color: $attributeHighlight;
            }
          }
        }
        #avatarPrimaryAttributes{
          svg{
            padding-right:.3em;
            font-size:7vh;
          }
          #armorDiv{
            svg{
              font-size:5.5vh !important;
            }
          }
        }
        #avatarElementalAttributes{
          > div{
            svg{
              font-size:4vh;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width:1024px){
  #home{
    #avatarSection{
      #topConsole{
      }
      #avatarQuickFacts{
        margin-top:1vh !important;
        font-size: 2vh;
        .avatar-name{
          font-size:9vmin;
        }
      }
    }
  }
}

@media screen and (max-width:599px){
  #home{
    #avatarSection{
      #topConsole{
        img{
          position: relative;
          z-index: 20;
          width: 100px;
          margin-top: -15px;
          margin-left: -15px;
        }
      }
      #avatarQuickFacts{
        margin-top:3vh !important;
        font-size: 3vh;
        .avatar-name{
          font-size:13vmin;
        }
        .avatar-title{
          margin-bottom: .5vh;
          font-size: .8rem;
        }
      }
    }
    #consoleSection{
      #battleReportLink{
        top: -11vh !important;
        font-size: .8em;
      }
    }
  }
}

// Pet
@media (max-width:719px) and (min-width: 600px){
  #home #avatarSection{
    .item-pet{
      width: 18vw;
    }
  }
  
}

@media screen and (min-width:950px) and (max-height:800px){
  #home #avatarSection {
    #petContainer{
      bottom: 0vw;
      .item-pet{
        bottom: 0vw;
      } 
    }
    #avatarContainer {
      bottom: 0vw;
    }
  }
  .background-elemental-event{
    @include background-elemental-event-desktop;  
  }
}

@media screen and (min-width:720px){
  .background-elemental-event{
    @include background-elemental-event-desktop;  
  }
}