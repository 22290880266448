@import "../../assets/styles/variables.scss";

.hexagon{
  font-family: 'Bebas Neue', cursive;

  &.hexagon-outside{
    z-index:10;
    position: relative;
    .top{
      width: 0;
    }
    .middle{
      display: flex;
    }
    .bottom{
      width: 0;
    }

    // When the number counter is counting up on a hexagon
    &.upgrading{
      .top{
        border-bottom-color: $mediumBackground !important;
      }
      .middle{
        background-color: $mediumBackground !important;
        svg{
          color : $mediumText !important;
        }
      }
      .bottom{
        border-top-color: $mediumBackground !important;
      }
    }
  }
  &.hexagon-inside{
    position: absolute;
    .top{
      width: 0;
      z-index:10;
      position:relative;
    }
    .middle{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index:11;
      overflow:visible;
      position:relative; 
      .text-wrapper{
        text-align:center;
        .hex-text{
          overflow: visible;
          line-height: 1em;
        }
      }
    }
    .bottom{
      width: 0;
      display:flex;
      justify-content:center;
      position: relative;
      z-index:10;
    }

    // When the number counter is counting up on a hexagon
    &.upgrading{
      .top{
        border-bottom-color: $highlight !important;
      }
      .middle{
        background-color: $highlight !important;
        svg{
          color : $mediumText !important;
        }
        .hex-text{
          color : $mediumText !important;
        }
      }
      .bottom{
        border-top-color: $highlight !important;
      }
    }
  
  }

  &.hexagon-no-border{
    position: relative;
    cursor: pointer;
    .top{
      width: 0;
      z-index:10;
      position:relative;
    }
    .middle{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index:11;
      overflow:visible;
      position:relative; 
      text-align:center;
      .text-wrapper{
        align-self: center;
        .hex-text{
          overflow: visible;
          line-height: 1em;
        }
      }

    }
    .bottom{
      width: 0;
      display:flex;
      justify-content:center;
      position: relative;
      z-index:10;
      
    }

  }
  &.hexagon-button{
    &.hexagon-inside{
      cursor : pointer;
      &:hover{
        .top{
          border-bottom-color: $mediumBackground !important;
        }
        .middle{
          background-color: $mediumBackground !important;
          svg{
            color : $reversedText !important;
          }
        }
        .bottom{
          border-top-color: $mediumBackground !important;
        }
      }
    }
  
    &.hexagon-no-border{
      cursor: pointer;
      &:hover{
        .top{
          border-bottom-color: $mediumBackground !important;
        }
        .middle{
          background-color: $mediumBackground !important;
          svg{
            color : $reversedText !important;
          }
        }
        .bottom{       
          border-top-color: $mediumBackground !important;
        }
      }
    }
  }

}