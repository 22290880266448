@import '../../assets/styles/variables.scss';
@import '../../assets/styles/background-mixins.scss';

#selectAvatarPage{
  display:flex;
  flex-direction:column;
  position:relative;

  #selectBackground{
    position: absolute;
    z-index: 1;
    top: 0;
    width:100%;
    height: 100%;
    .background-parallax{
      opacity:.5;
    }
  }

  .gallery{
    position:relative;
    z-index: 10;
    margin-top:50px;
    min-height:1200px;
    width:100%;
  }

  img{
    width:var(--width);
    height:var(--height);
    display :block;
    max-width: 100%;
  }
  
  &.background-dusk{
    @include background-dusk;
  }
}

/*

MEDIA QUERIES FOR HEXAGON GALLERY

*/
@media screen and (min-width:1600px){
  body #selectAvatarPage{

  }
}
@media screen and (min-width:1300px){
  body #selectAvatarPage{

  }
}

// Over 950px - Four Hex / three
@media screen and (min-width:936px){

  // ROW 1
  body #selectAvatarPage{
    min-height: 90vh;

    .gallery{
      min-height: 90vh;
      max-width:1000px;
    }
    
    .clipped-border:nth-child(1){
      top:50px;
      left:135px;
    }

    .clipped-border:nth-child(2){
      top:50px;
      left:370px;
    }

    .clipped-border:nth-child(3){
      top:50px;
      left:605px;
    }

    // ROW 2
    .clipped-border:nth-child(4){
      top:250px;
      left:17px;
    }
    .clipped-border:nth-child(5){
      top:250px;
      left:252px;
    }
    .clipped-border:nth-child(6){
      top:250px;
      left:487px;
    }
    .clipped-border:nth-child(7){
      top:250px;
      left:722px;
    }

    // ROW 3
    .clipped-border:nth-child(8){
      top:450px;
      left:135px;
    }
    .clipped-border:nth-child(9){
      top:450px;
      left:370px;
    }
    .clipped-border:nth-child(10){
      top:450px;
      left:605px;
    }
  }
}

// 718px -949px - Three Hex / two
@media screen and (max-width:935px) and (min-width:730px){

  // ROW 1
  body #selectAvatarPage{
    min-height: 120vh;
    .gallery{
      min-height: 120vh;
    }
    .clipped-border:nth-child(1){
      top:50px;
      left:118px;
    }
    .clipped-border:nth-child(2){
      top:50px;
      left:353px;
    }

    // ROW 2
    .clipped-border:nth-child(3){
      top:250px;
      left:0px;
    }
    .clipped-border:nth-child(4){
      top:250px;
      left:235px;
    }
    .clipped-border:nth-child(5){
      top:250px;
      left:470px;
    }

    // ROW 3
    .clipped-border:nth-child(6){
      top:450px;
      left:118px;
    }
    .clipped-border:nth-child(7){
      top:450px;
      left:353px;
    }

    // ROW 4
    .clipped-border:nth-child(8){
      top:650px;
      left:0px;
    }
    .clipped-border:nth-child(9){
      top:650px;
      left:235px;
    }
    .clipped-border:nth-child(10){
      top:650px;
      left:470px;
    }
  }
}

// Over 600px - Two Hex / Two
@media screen and (max-width:729px) and (min-width:600px){
  // ROW 1
  body #selectAvatarPage{
    min-height: 140vh;
    .galelry{
      min-height: 140vh;
    }
    .clipped-border:nth-child(1){
      top:50px;
      left:118px;
    }
    .clipped-border:nth-child(2){
      top:50px;
      left:353px;
    }

    // ROW 2
    .clipped-border:nth-child(3){
      top:250px;
      left:0px;
    }
    .clipped-border:nth-child(4){
      top:250px;
      left:235px;
    }

    // ROW 3
    .clipped-border:nth-child(5){
      top:450px;
      left:118px;
    }
    .clipped-border:nth-child(6){
      top:450px;
      left:353px;
    }

    // ROW 4
    .clipped-border:nth-child(7){
      top:650px;
      left:0px;
    }
    .clipped-border:nth-child(8){
      top:650px;
      left:235px;
    }
    // ROW 5
    .clipped-border:nth-child(9){
      top:850px;
      left:118px;
    }
    .clipped-border:nth-child(10){
      top:850px;
      left:353px;
    }
  }
}

// Over 485px - Two Hex / One
@media screen and (max-width:599px) and (min-width:501px){
  // ROW 1
  body #selectAvatarPage{
    min-height: 180vh;
    .gallery{
      min-height: 180vh;
    }
    .clipped-border:nth-child(1){
      top:50px;
      left:118px;
    }
    // ROW 2
    .clipped-border:nth-child(2){
      top:250px;
      left:0px;
    }
    .clipped-border:nth-child(3){
      top:250px;
      left:235px;
    }
    // ROW 3
    .clipped-border:nth-child(4){
      top:450px;
      left:118px;
    }
    // ROW 4
    .clipped-border:nth-child(5){
      top:650px;
      left:0px;
    }
    .clipped-border:nth-child(6){
      top:650px;
      left:235px;
    }
    // ROW 5
    .clipped-border:nth-child(7){
      top:850px;
      left:118px;
    }
    // ROW 6
    .clipped-border:nth-child(8){
      top:1050px;
      left:0px;
    }
    .clipped-border:nth-child(9){
      top:1050px;
      left:235px;
    }
    // ROW 7
    .clipped-border:nth-child(10){
      top:1250px;
      left:118px;
    }
  }
}

@media screen and (max-width:500px){
  // Under 485px - One Hex per row max
  // & Base Styles for larger media queries
  #selectAvatarPage{
    min-height: 320vh;
    .gallery{
      margin-top:0px;
      min-height: 320vh;
    }
    .clipped-border:nth-child(1){
      top:50px;
      left:118px;
      background:linear-gradient(white, darkslategrey);
    }
    .clipped-border:nth-child(2){
      top:250px;
      left:0px;
      background:linear-gradient(gold, goldenrod);
    }
    .clipped-border:nth-child(3){
      top:450px;
      left:118px;
      background:linear-gradient(goldenrod, sienna);
    }
    .clipped-border:nth-child(4){
      top:650px;
      left:0px;
      background:linear-gradient(forestgreen, black);
    }
    .clipped-border:nth-child(5){
      top:850px;
      left:118px;
      background:linear-gradient(ivory, dimgrey);
    }
    .clipped-border:nth-child(6){
      top:1050px;
      left:0px;
      background:linear-gradient(springgreen, black);
    }
    .clipped-border:nth-child(7){
      top:1250px;
      left:118px;
      background:linear-gradient(burlywood, forestgreen);
    }
    .clipped-border:nth-child(8){
      top:1450px;
      left:0px;
      background:linear-gradient(peru, sienna);
    }
    .clipped-border:nth-child(9){
      top:1650px;
      left:118px;
      background:linear-gradient(pink, red);
    }
    .clipped-border:nth-child(10){
      top:1850px;
      left:0px;
      background:linear-gradient(lightgrey, darkslategrey);
    }

  }

  .MuiInputBase-root{
    width:100% !important;
    padding:10px;

  }
}

// Hex background colors
#selectAvatarPage{
  display:flex;
  align-items:center;
  .gallery{
    width: 100%;
    overflow-x:hidden;
  }
  .clipped-border:nth-child(1){
    background:linear-gradient(white, darkslategrey);
  }
  .clipped-border:nth-child(2){
    background:linear-gradient(gold, goldenrod);
  }
  .clipped-border:nth-child(3){
    background:linear-gradient(goldenrod, sienna);
  }
  .clipped-border:nth-child(4){
    background:linear-gradient(forestgreen, black);
  }
  .clipped-border:nth-child(5){
    background:linear-gradient(ivory, dimgrey);
  }
  .clipped-border:nth-child(6){
    background:linear-gradient(springgreen, black);
  }
  .clipped-border:nth-child(7){
    background:linear-gradient(burlywood, forestgreen);
  }
  .clipped-border:nth-child(8){
    background:linear-gradient(peru, sienna);
  }
  .clipped-border:nth-child(9){
    background:linear-gradient(pink, red);
  }
  .clipped-border:nth-child(10){
    background:linear-gradient(lightgrey, darkslategrey);
  }
  .clipped-border{
    -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
    padding:5px;
    background:linear-gradient(lightgrey, rgb(164, 164, 164));
    width:var(--border-width);
    height:var(--border-height);
    max-height:500px;
    max-width:500px;
    height: 250px;
    width: 250px;
    transition:transform 0.2s;
    position:absolute;
    cursor:pointer;
    overflow:hidden;
  }



  .clipped-border:before{
    content:'';
    overflow:hidden;
    position:absolute;
    opacity:0.5;
    width:350px;
    height:70px;
    background:white;
    top:0;
    left:0;
    z-index:1;
    transform:rotate(45deg);
    transition:transform 0.5s;
  }

  .clipped-border:hover:before{
    transform: translate(-100px,400%) rotate(45deg);
    transition:transform 0.5s;
  }

  

  #clipped {
  -webkit-clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);

  }

  .clipped-border:hover{
    transform:scale(1.4);
    transition:transform 0.2s;
    z-index:10;
  }
}


// @media screen and (max-width:999px) and (min-width:900px){
//   #selectAvatarPage .gallery{
//     width: 76%;
//   }
// }

@media screen and (max-width:899px) and (min-width:835px){
  #selectAvatarPage .gallery{
    width: 100%;
  }
}

@media screen and (max-width:425px){
  #selectAvatarPage{
    .gallery{
      min-height: 340vh;
    }
  }
}