$primaryBackground : #e8eddf;
$secondaryBackground : #ebebeb;
$lightBackground : #f1c85b;
$mediumBackground : #333533;
$mediumLightBackground : #494e49;
$darkBackground : #242423;
$primaryButton : #494e49;
$secondaryButton : #d6d6c0;
$darkText : #242423;
$speechText : rgba(36, 36, 35, .8);
$newsprintText : #1A1C1A;
$newsprintTextLight : #4D4D4D;
$mediumText : #333533;
$lightText : #356735;
$reversedText: #e8eddf;
$reversedTextAlt: #cfdbd5;
$linkText : #000080;
$reversedLinkText : #3792cb;
$warningText : #BF0000;
$cautionText : #ff6700;
$disabledText : #212121;
$successText : #356735;
$primaryBorder : #e8eddf;
$mediumBorder : #333533;
$highlight :  #f1c85b;
$warning : #BF0000;
$brandHighlight: #d4af37;
$fire : #e25822;
$earth : #8A360F;
$water : #0f5e9c;
$air : #16a0f5;
$aether : #FFFFC2;
$health : #A30216;
$attributeHighlight: #77CA1D;
$stravaHighlight: #FC4C02;
$muted : .3;
// FONTS
$headline : 'Bebas Neue',Helvetica,Arial,sans-serif;
$narrowHeadline : 'Pathway Gothic One',Helvetica,Arial, sans-serif;
$wide : 'Spartan', Helvetica, Arial, sans-serif;
$narrow : 'Pragati Narrow',Helvetica,Arial,sans-serif;
$newsHeadline: 'Teko', sans-serif;
$newsText: 'Faustina', serif;
$cursiveText: 'Rochester', cursive;
