@import '../../../assets/styles/variables.scss';

.info-about-activities{
  display:flex;
  flex-direction: column;
  color: $mediumText;
  .row-title{
    font-family: $narrowHeadline;
    font-size: 1.5em;
  }
  .about-row{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    border-top: solid 1px $mediumBorder;
    padding: 10px 0px;
    font-family: 'Pragati Narrow';
    &.about-row-no-border{
      border-top: initial;
    }
    &:last-of-type{
      border-bottom: solid 1px $mediumBorder;
    }
    .about-activity-wrapper{
      
      flex:1;
      svg{
        font-size:2em;
      }
    }
    .about-description-wrapper{
      flex:1;
      font-size:2em;
      display:flex;
      align-items:center;
      justify-content:center;
      text-align:center;

      &.about-description-level-up{
        flex-direction:column;
        padding-right:10%;
        svg{
          font-size:1.8em;
        }
        a{
          font-size:1rem;
        }
      }
      svg{
        margin-top:-5px;
      }
      strong{
        display: block;
        padding:0px 10px 0px 30px;
        font-size:1.5em;
      }
      span{
        font-size:.8em;
      }
    }
    .about-hex-wrapper{
      display:flex;
      align-items:center;
      justify-content:flex-end;

      flex:1;
    }
    
  }
}
@media screen and (max-width:599px){
  .info-about-activities{
    .about-row{
      flex-direction: column;
      .about-description-level-up{
        padding-right:initial !important;
      }
    }
  }

   



}