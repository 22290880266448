@import "../../assets/styles/variables.scss";

header{
  position:relative;
  z-index:10;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  text-align:center;
  width:100%;
  background-color: $darkBackground;
  color: $reversedTextAlt;
  font-size: calc(10px + 2vmin);
  min-height:169px;
  h1{
    position:relative;
    font-family: 'Bebas Neue', Helvetica, Arial, sans-serif;
    text-shadow: 3px 3px 2px black;
    width:100%;
    z-index: 10;
    font-size: 4em;
    margin: .2em 0 0;
    padding:0px 0px 0px 0px;
    color: $brandHighlight;
  }
  strong{
    font-family: $narrowHeadline;
    font-size:1em;
    display:block;
    margin-bottom:20px;
    opacity:.5;
  }
}

@media screen and (max-width:400px){
  header{
    text-align:left;
    justify-content: flex-start;
    align-items: flex-start;
    h1{
      box-sizing: border-box;
      width:100%;
      padding:10px 0px 0px 10px;
    }
    strong{
      box-sizing: border-box;
      padding:0px 0px 0px 10px;
    }
  }
}