@import "../../assets/styles/variables.scss";

#aboutPage,
#battlesPage,
#signupRewardsPage {
 h2 {
  margin: 0px;
  font-family: $headline;
  font-size: 4em;
  color: $mediumText;
 }
 .about-segment {
  min-height: 550px;
  color: $darkText;
  width: 100%;
  p {
   line-height: 1.7em;
  }
  .segment-content {
   .segment-header-wrap {
    strong {
     font-family: $headline;
    }
   }
   .section-img {
    text-align: center !important;
    .pet {
     width: 300px;
    }
   }
  }

  &:nth-of-type(even) {
   background-color: white;
   .segment-content {
    .section-img {
     float: left !important;
    }
   }
  }
  &:nth-of-type(odd) {
   text-align: right;
   .segment-content {
    .section-img {
     float: right !important;
    }
   }
  }
  &:nth-of-type(1) {
   background-image: url("../../assets/images/layout/desktop-loading.webp");
   background-position: bottom right;
   background-size: cover;
   background-repeat: no-repeat;
   .segment-content {
    min-height: 550px !important;
    padding: 0px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8eddf+0,e8eddf+100&0+0,1+28 */
    background: -moz-linear-gradient(left, rgba(232, 237, 223, 0) 0%, rgba(232, 237, 223, 1) 75%, rgba(232, 237, 223, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(232, 237, 223, 0) 0%, rgba(232, 237, 223, 1) 75%, rgba(232, 237, 223, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
     to left,
     rgba(232, 237, 223, 0) 0%,
     rgba(232, 237, 223, 1) 75%,
     rgba(232, 237, 223, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e8eddf', endColorstr='#e8eddf',GradientType=1 ); /* IE6-9 */
    > div {
     padding: 20px;
     max-width: 500px;
     float: right;
     text-align: left;
     #villainWrapper {
      min-height: 500px;
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 400px;
      .Shade-background {
       background-image: url("../../assets/images/foes/spirits/shade/shade.webp");
       filter: opacity(100%);
       height: 550px;
       width: 420px;
       background-repeat: no-repeat;
       background-size: cover;
      }
     }
    }
   }
  }
  &:nth-of-type(2) {
   overflow: hidden;
   .segment-content {
    .segment-header-wrap,
    #segmentHeaderWrap {
     float: left;
     max-width: 500px;
     strong {
      font-family: $headline;
     }
    }
    > div {
     #logoAbout {
      height: 500px;
      width: 500px;
      position: absolute;
      bottom: -25px;
      right: 0px;
      background-image: url("../../assets/images/misc/logo.webp");
      background-repeat: no-repeat;
     }
    }
   }
  }
  &:nth-of-type(3) {
   background-image: url("../../assets/images/layout/mobile-loading.webp");
   background-position: bottom left;
   background-size: 50%;
   background-repeat: no-repeat;
   .info-about-activities {
    text-align: left;
    max-width: 700px;
    margin: 0px auto;
   }
   .segment-content {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e8eddf+0,e8eddf+100&0+0,1+28 */
    background: -moz-linear-gradient(left, rgba(232, 237, 223, 0) 0%, rgba(232, 237, 223, 1) 50%, rgba(232, 237, 223, 1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(232, 237, 223, 0) 0%, rgba(232, 237, 223, 1) 50%, rgba(232, 237, 223, 1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
     to left,
     rgba(232, 237, 223, 0) 0%,
     rgba(232, 237, 223, 1) 50%,
     rgba(232, 237, 223, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e8eddf', endColorstr='#e8eddf',GradientType=1 ); /* IE6-9 */

    height: 100%;
   }
   #activityIncrementMessage {
    padding: 20px;
    text-align: center;
    color: $lightText;
    font-family: $narrow;
    font-size: 1em;
   }
  }
  &:nth-of-type(4) {
   #vsContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    .vs-item {
     img {
      max-width: 350px;
     }
     .hero-name {
      display: block;
      font-size: 3em;
      font-family: $narrowHeadline;
      text-transform: uppercase;
     }
     #vs strong {
      font-size: 7vmin;
      font-family: $headline;
      text-align: center;
     }
     &:nth-of-type(1) {
      :not(.hero-name) {
       -webkit-transform: scaleX(-1);
       transform: scaleX(-1);
      }
     }
    }
   }
  }
  &:nth-of-type(5) {
   max-width: 100%;
   background-image: url("../../assets/images/layout/long-elemental-background.webp");
   background-position: center bottom;
   background-repeat: no-repeat;
   .segment-content {
    background: linear-gradient(to bottom, rgba(232, 237, 223, 0) 0%, #e8eddf 75%, #e8eddf 100%);
   }
  }

  #percentTable,
  #interactionTable {
   overflow-x: scroll;
   tbody {
    &:first-of-type {
     span {
      font-size: 0.8em;
      opacity: 0.5;
     }
    }
    tr {
     &.fire-highlight {
      strong {
       color: $fire;
      }
     }
     &.earth-highlight {
      strong {
       color: $earth;
      }
     }
     &.water-highlight {
      strong {
       color: $water;
      }
     }
     &.air-highlight {
      strong {
       color: $air;
      }
     }
     &.aether-highlight {
      strong {
       color: $aether;
      }
     }
    }
   }
  }
  h3 {
   font-family: $narrowHeadline;
  }
  p,
  ul li {
   font-family: $narrow;
  }
  #traitBreakdown {
   em {
    font-family: $narrowHeadline;
   }
   .element-container {
    position: relative;
    .proc {
     position: absolute;
     left: 20px;
     top: 0px;
     svg {
      font-size: 5em;
      opacity: 0.5;
     }
    }
   }
  }
  &.about-segment-reddit,
  .about-segment-all-pets {
   min-height: initial;
   text-align: center;
   border-top: solid 1px $mediumBackground;
   text-align: center;
   font-family: $narrowHeadline;
   font-size: 1.2em;
   padding: 20px 0px;
   .segment-content {
    min-height: initial;
   }
  }
  &.about-segment-all-pets {
   min-height: initial !important;
   .segment-content {
    min-height: initial !important;
    padding: 10px 0px;
    text-align: center;

    width: 100%;
    h3 {
     font-size: 1.2em;
     font-family: $headline;
     font-weight: bold;
     margin-top: 20px;
    }
    #petContainer {
     display: flex;
     justify-content: center;
     img {
      width: 150px !important;
     }
    }
   }
  }
  #ptSection {
   text-align: center;
   width: 100%;
   padding-top: 50px;
   div {
    width: 100%;
   }
  }
  .segment-content {
   padding: 20px;
   position: relative;
   height: 100%;
   min-height: 500px;

   > div {
   }
  }
 }
}

@media screen and (max-width: 920px) {
 #aboutPage,
 #battlesPage {
  .about-segment {
   &:nth-of-type(1) {
    .segment-content {
     > div {
      max-width: 100% !important;
      #villainWrapper {
       position: relative !important;
       min-height: 50% !important;
       width: 200px;
       margin: 0px auto;
       .Shade-background {
        height: 225px;
        width: 210px;
       }
      }
     }
    }
   }
   &:nth-of-type(2) {
    overflow: visible;

    .segment-content {
     > div {
      #logoAbout {
       bottom: -200px;
       right: 0px;
      }
     }
    }
   }
  }
 }
}

@media screen and (max-width: 599px) {
 #aboutPage,
 #battlesPage {
  .about-segment {
   p {
    text-align: justify;
   }
   &:nth-of-type(1) {
    min-height: 850px;
    background-position: bottom center;
    background-size: 200%;
   }
   &:nth-of-type(2) {
    min-height: 650px;
    background-position: bottom center;
    background-size: 50%;
    > div {
     #logoAbout {
      height: 500px;
      width: 500px;
      position: absolute;
      bottom: 0px;
      right: 50%;
      margin-right: -150px;
      background-size: 70%;
      opacity: 0.5;
     }
    }
   }
   &:nth-of-type(4) {
    #vsContent {
     .vs-item {
      img {
       max-width: 250px;
      }
     }
    }
   }
  }
 }
}

#signupRewardsPage {
 .segment-content {
  p {
   font-size: 1.2em;
  }
 }
}
