@import '../../assets/styles/variables.scss';

#profilePage{
  min-height:500px;
  #userProfile{
    h3{
      font-family: $headline;
      border-bottom: solid 5px $brandHighlight;
      font-size:2em;
      display:inline-block;
    }
  }
  #loadingContainer{
    background-image: url('../../assets/images/layout/desktop-loading.webp');
    background-size: cover;
    width:100%;
    height:100%;
  }
  .panel{
    margin-bottom:0px !important;
    &#avatarDescription{
      padding: 20px;
      background: -moz-linear-gradient(-180deg, #ffffff 0%, #e8eddf 60%, #e8eddf 60%); /* FF3.6-15 */
      background: -webkit-linear-gradient(-180deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(-180deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      .avatar-name{
        font-size:8vmin;
      }
    }
    &.panel-hero{
      padding:20px;
      .social-sharing-profile{
        flex: 1;
        text-align:center;
        .share-herofit-text{
          font-family: $narrowHeadline;
          margin-bottom:10px;
          strong{
            display:block;
            font-size:1.2em;
          }
        }
      }
      .avatar-img-container {
        flex: 2;
      }
      display: flex;
      flex-direction: column;
    }
    &.panel-stats{
      background-color: #fff;
      border-top: solid 3px $brandHighlight;
      border-bottom: solid 3px $brandHighlight;
      padding: 20px;
      #primaryAttributes{
        display: flex;
        align-items:center;
        flex-direction:column;
        #r1{
          display:flex;
          flex-direction: row;
          > .hexagon{
            margin-right:10px;
            &:last-of-type{
              margin-right:0px;
              margin-top:65px;
            }
          }
        }
        #r2{
          display:flex;
          flex-direction: row;
          > .hexagon{
            margin-right:10px;
            margin-top:-15px;
            &:last-of-type{
              margin-top:-50px;
              margin-right:0px;
            }
          }
        }
      }
      #secondaryAttributes{
        display:flex;
        flex-direction:row;
        justify-content:center;
        > .hexagon{
          margin-right:10px;
          &:last-of-type{
            margin-right:0px;
          }
        }
      }
    }
    &.panel-pets{
      h3{
        margin-bottom: 0px;
      }
      #petWrapper{
        height:230px;
        overflow-y: scroll;
        display:flex;
        flex-wrap: wrap;
      }
      .pet-content-container{
        align-items:center;
        padding: 5px;
        cursor: pointer;
        &:hover{
          background-color: $highlight;
        }
        &:nth-of-type(odd){
    
        }
        img{
          width:110px;
        }
      }
    }
    &.panel-skins{
      #skinWrapper ul{
        height:230px;
        overflow-y: scroll;
        display:flex;
        flex-wrap: wrap;
        margin:0px;
        padding: 0px;
      }
      .skin-content-container{
        align-items:center;
        padding: 5px;
        cursor: pointer;
        &:hover{
          background-color: $highlight;
        }
        img{
          width:110px;
        }
      }
    }
    &#battles{
      flex-direction: column;
      display: flex;
      h3{
        margin-bottom:0px;
        width:85px;
      }
      ul{
        margin:0px;
        padding:0px;
        list-style-type: none;
        overflow-y: scroll;
        height:230px;
        li{
          font-family: $narrowHeadline;
          &:nth-of-type(even){
            background-color: #fff;
          }
          .battle{
            cursor: pointer;
            &.battle-tbd{
              opacity:.3;
              cursor: default;
            }
          }
          #b1, #b2,#b3{
            display:inline-block;
            width:33%;
            text-align:center;
            .left-battle-img{
              img{ width:100px;}
            }
            .right-battle-img{
              img{ 
                width:100px; 
              }
              &.spirit{
                img{
                  width:60px; 
                }
              }
            }
          }
          #b2{
            strong{
              font-size:10vmin;
              vertical-align:top;
              margin-top:10px;
              font-family: $headline;
            }
          }
          .outcome{
            text-align:center;
            font-weight: bold;
            background-color: $secondaryBackground
          }
        }
      }
    }
  }
  #avatarDescription{
    font-size:1.3rem;
    max-height: 475px;
    overflow-y:scroll !important;
    .avatar-title{
      font-size:1.3rem;
      color: $lightText;
      font-family: $narrow;

    }
    .xp-generator{
      font-family: $narrowHeadline;
      strong{
        display:inline;
        font-size:1.3em;
      }

    }
    .avatar-name{
      line-height:1em;
      color: $mediumText;
      max-width:400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $headline;
    }
    .owner-username{
      color: $mediumText;
      max-width:400px;
      font-size: 1.2em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $narrowHeadline;
    }
    .avatar-level{
      color: $lightText;
      font-family: $narrowHeadline;
      display: inline;
      strong{
        display: inline;
      }
    }
    .total-xp{
      font-size:1.3rem;
      font-family: $narrowHeadline;
      display: inline;
      .muted{
        opacity:.5;
      }
    }
    .title-descriptor{
      cursor: pointer;
      white-space: initial;
      font-size: .9em;
      font-family: $narrow;
      background-color: $primaryBackground;
      padding: 5px;
      border-radius: 8px;
      margin-top: 20px;
      &:hover{
        background-color: $highlight;
      }
      strong{
        color : $lightText;
        svg{
          margin-right: 7px;
        }
      }
      .title-description{
        margin: .5em;
        color: $mediumText;
      }
    }
  }
  #imgContainer{
    position:relative;
    min-width:190px;
    text-align:center;
    .avatar-img-container{
      position:relative;
      z-index:10;
      width: 90%;
      height: auto;
      margin-left:0px;
      
      img{
        min-height:200px;
        width:100%;
        height:auto;
        max-width:350px;
        cursor: pointer;
      }
      
    }
  }
  #latestActivitiesWrapper{
    overflow-x: scroll;
    #latestActivityTable{
      border-top: solid 3px $brandHighlight;
    }
  }

  #searchWrapper{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    #findUser{
      width:100%;
      min-height:500px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items:center;
      background-color: #fff;
      padding: 20px;
      margin: 20px;
      font-family: $narrowHeadline;
      #searchContainer{

        position:relative;
        input{
          z-index:0;
          margin: 20px 20px 20px;
          font-size:1em;
          padding:10px;
          display: block;
          font-family: $wide;
          border-radius:6px;
          border: solid 1px $mediumText;
        }
        span{
          
          z-index:100;
          position:absolute;
          left:0px;
          top:0px;
          svg{
            color:grey;
            position:absolute;
            left:30px;
            top: 9px;
          }
        }
      }
      #userList{
        ul{
          margin: 0px;
          padding: 20px;
          li{
            padding: 10px 0px;
          }
        }
        
        .link-text{
          text-decoration: underline;
        }
      }
    }
    }
}

@media screen and (min-width:1024px){
  #profilePage{
    #userProfile{
      display:flex;
      flex-direction:row;
      flex-wrap: wrap;
      justify-content: space-between;
      h3{
        font-family: $headline;
        border-bottom: solid 5px $brandHighlight;
        font-size:2em;
        display:inline-block;
      }
      .panel{
        flex: 0 28%;
        margin-bottom: 2%; /* (100-32*3)/2 */

        &#avatarDescription{
          background: -moz-linear-gradient(-245deg, #ffffff 0%, #e8eddf 60%, #e8eddf 60%); /* FF3.6-15 */
          background: -webkit-linear-gradient(-245deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(-245deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          max-width:300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.panel-stats{
          background: -moz-linear-gradient(-115deg, #ffffff 0%, #e8eddf 60%, #e8eddf 60%); /* FF3.6-15 */
          background: -webkit-linear-gradient(-115deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(-115deg, #ffffff 0%,#e8eddf 60%,#e8eddf 60%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          border-top: none;
          border-bottom: none;
        }
        &.panel-pets{
          #petWrapper{
            .pet-content-container{
              img{
                width: 90px;
              }
            }
          }
        }
        &.panel-skins{
          #skinWrapper{
            .skin-content-container{
              img{
                width: 90px;
              }
            }
          }
        }
        &#battles{
          strong{
            font-size:15vmin;
          }
        }
      }
      #avatarDescription{
        .avatar-title{
          .title-description{
            font-size: .8 em;
            opacity : $muted;
          }
          svg {
            margin-right: 7px;
          }
        }
        .battle-stats{
  
          strong{
     
          }
    
        }
        .avatar-name{
    
    
        }
        .avatar-level{
  
          strong{
  
          }
        }
        .total-xp{
  
          .muted{
          
          }
        }
      }
      #imgContainer{
        width:100%;
        .avatar-img-container{
          width: 100%;
          height: auto;
          img{
            width:100%;
            height:auto;
            cursor: pointer;
          }
        }

      }
    }
    #searchWrapper{
      #findUser{
        width:60%;
      }
    }
  }
}

@media screen and (max-width:720px){
#profilePage{
    .avatar-name{
      font-size:14vmin !important;
    }

    #loadingContainer{
      background-image: url('../../assets/images/layout/mobile-loading.webp');
    }
    #avatarDescription{
      max-height: 275px;

    }

  .panel-hero{
    padding:20px;
    .social-sharing-profile{
      margin-bottom: 20px;
    }
  }

  }
  #userProfile{
    display:flex;
    flex-direction:column;
    text-align:center;
    .panel{
      &:nth-of-type(1){
        order: 2;
      }
      &:nth-of-type(2){
        order: 1;
      }
      &:nth-of-type(3){
        order: 3;
      }
      &:nth-of-type(4){
        order: 4;
      }
      &:nth-of-type(5){
        order: 5;
      }
      &:nth-of-type(6){
        order: 6;
      }
      &.panel-hero{
        padding:20px 0px;
      }
    }
  }

}