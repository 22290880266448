@import '../../assets/styles/variables';

#rankingPage{
  .subheader{
    display:flex;
    align-items:center;
    justify-content:space-between;
    text-align:center;
    //background-color: $primaryBackground;
    #formSelection{
      .MuiFormLabel-root{
        z-index: 1;
        background-color: $primaryBackground;
      }
    }
    #searchContainer{
      position:relative;
      input{
        z-index:0;
        margin: 20px 20px 20px;
        font-size:1em;
        padding:10px;
        padding-left: 40px;
        display: block;
        font-family: $wide;
        border-radius:6px;
        border: solid 1px $mediumText;
        &:focus + span{
          display:none !important;
        }

      }
      span{
        
        z-index:100;
        position:absolute;
        left:0px;
        top:0px;
        svg{
          color:grey;
          position:absolute;
          left:30px;
          top: 28px;
        }
      }
    }
  }
  .pagination-section{
    padding:10px;
    &:last-of-type{
      .MuiPagination-ul{
        justify-content: center;
      }
    }
  }
  #allAvatars{
    background-image: url('../../assets/images/layout/desktop-loading.webp');
    background-size: cover;
    .avatar-container{
      display:flex;
      align-items:center;
      justify-content: center;
      position:relative;
      text-align: center;
      width:100%;
      border-bottom: solid 2px $mediumBorder;
      overflow:hidden;
      &:first-of-type{
        border-top: solid 2px $mediumBorder;
      }
      &:nth-of-type(even){
        background-color: $primaryBackground;
      }
      &:nth-of-type(odd){
        background-color: #FFF;
      }
      .rank-col{
        font-family: $headline;
        background-color:$brandHighlight;
        color: $mediumText;
        flex: 0;
        flex-basis: calc(30% - 150px);
        min-height: 100%;
        min-width:150px;
        align-self: stretch;
        display:flex;
        overflow:hidden;
        align-items:center;
        font-size:2.5em;
        .rank-col-content{
          position:relative;
          padding:20px;
          border-radius: 6px;
          color: $reversedText;
          
          span, strong{
            display:inline-block;
            
          }
          .rank{
            font-size:3em;
            line-height:.7em;
            //text-shadow: 2px 2px 2px $reversedTextAlt, -2px -2px 2px $reversedTextAlt;
            padding: 0px 7px;
            color: $mediumText;
          }
          .suffix{
            color: $mediumText;
            position:absolute;
            right:0px;
            margin-right: -5px;
            top:10px;
            opacity:.6;
            text-decoration:underline;
          }
        }

      }
  

      a.main-col{
        display:flex;
        flex: 1 1 80%;
        justify-content: space-between;
        text-align:left;
        text-decoration:none;
        color:inherit;
        width:100%;
        
        &:hover{
          background-color: $secondaryBackground;
        }
        .name-subcol{
          padding:20px;
          font-family: $narrow;
          max-width:33%;

          .avatar-title{
            font-size:.8em;
            color: $lightText;
            font-family: $narrow;
            svg {
              margin-right: 7px;
              font-size: 1.1rem;
            }
            &.air-highlight{
              color: $air;
            }
            &.earth-highlight{
              color: $earth;
            }
            &.fire-highlight{
              color: $fire;
            }
            &.water-highlight{
              color: $water;
            }
          }
          .avatar-description{
            .extra-stats{
              font-family: $narrowHeadline;
              strong{
                display:inline;
                font-size:.9em;
              }

            }
            .avatar-name{
              font-size:7vmin;
              line-height:1em;
              color: $mediumText;
              max-width:400px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-family: $headline;
    
            }
            .level-row{
              .avatar-level{
                color: $lightText;
                font-family: $narrowHeadline;
                display: inline;
                strong{
                  display: inline;
                }
              }
            }

            .total-xp{
              font-size:1rem;
              font-family: $narrowHeadline;
              display: inline;
              .muted{
                opacity:.5;
              }
            }
            .player-name{
              font-size:.9em;
              opacity:.3;
              font-family: $narrow;
              max-width:20vw;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
        .stats-subcol{
          .attributes{
            display:flex;
            flex-direction:column;
            justify-content:center;
            height:100%;
            align-items:center;
            opacity:.7;
            > div{
              display:block;
              align-self:center;
              .hexagon{
                display:inline-block;
                padding:1px;
              }
              &:nth-of-type(even){
                margin-top:-15px;
                margin-left: 44px;
              }
              &.aether-row{
                &:nth-of-type(even){
                  margin-top:-15px;
                  margin-left: 0px;
                }
              }
            }
          }
        }
        .img-subcol{
          position:relative;
          min-width:190px;
          .avatar-img-container{
            position:relative;
            z-index:10;
            display:inline-block;

            margin-left:0px;
            img{
              min-height:165px;
              height:17vh;
              &.firelord{
                filter : hue-rotate(50deg) saturate(800%);
              }
              &.earthshaker{
                filter : sepia(100%)  contrast(120%) brightness(110%);
              }
            }
            
          }

          .item-pet{
            position:absolute;
            z-index:100;
            width:60px;
            
            left:0px;
            bottom:0;


          }
        }
      }


      
    }
    .no-heroes-indicator{
      min-height:400px;
      //text-align:center;
      background-color: #fff;
      display:flex;
      align-items:center;
      justify-content: center;
      font-family: $headline;
      font-size: 5vmin;
      color: $mediumText;
    }
  }

}
@media (min-width:1200px){
  #allAvatars{
    .avatar-container{
      .rank-col{
        justify-content:center;
      }
    }
  }
}

@media (max-width:999px) and (min-width:721px){
  #allAvatars{
    .avatar-container{
      .main-col{
        a{
          .name-subcol{
            .avatar-name{
              font-size:1.4em !important;
              max-width:200px !important;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }

}

@media screen and (max-width:720px){
  #rankingPage{
    #allAvatars{
      background-image: url('../../assets/images/layout/mobile-loading.webp');
    }
    .subheader{
      justify-content:center;
      flex-direction: column-reverse;
    }
    .rank-col{
      min-width:80px !important;
      flex:auto;
      justify-content:center;
      .rank-col-content{
        padding:0px;
        .rank{
          font-size:1.5em !important;
        }
        .suffix{
          display:none !important;
        }
      }
    }
    a.main-col{
      display:block !important;
      justify-content: space-between;
      .subcol{
        display:block;
        &.name-subcol{
          padding:10px 10px 0px !important;
          max-width:100% !important;
          .avatar-name{
            max-width:100% !important;
          }
          .player-name{
            max-width:70vw !important;
          }
        }
        &.stats-subcol{
  
        }
        &.img-subcol{
          text-align:center;
          .item-pet{
            bottom:5% !important;
            right:0px !important;
            left:initial !important;
          }
        }

      }
    }
  }

}

@media screen and (max-width:400px){
  .img-subcol{

    .avatar-img-container{
      margin-left:-50px;
    }
  }
}




