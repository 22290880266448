/**
 * ----------------------------------------
 * animation shake 
 * ----------------------------------------
 */
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/**
 * ----------------------------------------
 * animation float
 * ----------------------------------------
 */
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-then-down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes scale-up-then-down {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

/**
 * ----------------------------------------
 * animation fade in
 * ----------------------------------------
 */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.9;
  }
}

/**
 * ----------------------------------------
 * animation fade out
 * ----------------------------------------
 */
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade in then out
 * ----------------------------------------
 */
@keyframes fadeInThenOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeInThenOut {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * contract expand
 * ----------------------------------------
 */
@keyframes contractExpand1 {
  0% {
    transform: rotate(0deg) translate(0px);
  }
  50% {
    transform: rotate(0deg) translate(15px, 15px);
  }
  100% {
    transform: rotate(0deg) translate(0px);
  }
}
@keyframes contractExpand2 {
  0% {
    transform: rotate(90deg) translate(0px);
  }
  50% {
    transform: rotate(90deg) translate(15px, 15px);
  }
  100% {
    transform: rotate(90deg) translate(0px);
  }
}
@keyframes contractExpand3 {
  0% {
    transform: rotate(180deg) translate(0px);
  }
  50% {
    transform: rotate(180deg) translate(15px, 15px);
  }
  100% {
    transform: rotate(180deg) translate(0px);
  }
}
@keyframes contractExpand4 {
  0% {
    transform: rotate(270deg) translate(0px);
  }
  50% {
    transform: rotate(270deg) translate(15px, 15px);
  }
  100% {
    transform: rotate(270deg) translate(0px);
  }
}
