@import '../../assets/styles/variables.scss';
#dialogWrapper{
  position:relative;
}
.dialog-template{
  &.dialog-template-loading{
    
    #dialogTopImage{
      display:none !important;
    }
    #dialogCancel{
      z-index:1000;
    }
    #loadingModalOverlay{
      z-index: 0;
    }
  }
  &.dialog-template-speech{
    .MuiDialogContentText-root{
      overflow:visible;
    }
    div.MuiDialog-container{
      position:relative;
      div[role="dialog"]{
        position:absolute;
        top: 100px;
      }
    }
    .MuiDialogContentText-root{
      padding:20px 40px;
      background-color: $primaryBackground;
      border-radius: 6px;
      color:$darkText;
      color: $speechText;
      font-style: italic;
      font-size: .8em;
      text-align:center;
      margin: 15px 8px 8px;
      position: relative;
      .arrow-up {
        display:inline-block;
        position:absolute;
        left:50%;
        top:-16px;
        margin-left:-16px;
        width: 0; 
        height: 0; 
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 16px solid $secondaryBackground;

        
      }
    }
    .MuiDialogContent-root{
      padding: 0px;
      
    }
    
    .content-wrapper{
      border-top: solid 1px $darkText;
      background-color:$primaryBackground;
      padding-bottom: 20px;
      min-height: 100%;
      > div{
        height: 100%;
      }
    }
    
  }
  &.dialog-template-basic, .dialog-template-feedback{
    
    .MuiDialogTitle-root{
      h2{
        margin-top:10px;
        font-family: 'Bebas Neue', Helvetica, Arial, sans-serif;
        font-size: 2em;
      }
    }
    .MuiDialog-scrollPaper{
      margin-top:30px;
    }
  }
  &.dialog-template-headless{
    #dialogTopImage{
      display:none;
    }
    .MuiDialog-container{
      //overflow-y:scroll;
      .MuiPaper-rounded{
        overflow-y:scroll;
        .MuiDialogContent-root{
          overflow-y:scroll;
          .content-wrapper{
            overflow-y:scroll;
          }
        }
      }
    }

  }
  .MuiTypography-colorTextSecondary{
    max-height: 20vh !important ;
    overflow: scroll;
  }
  .MuiPaper-root{
    overflow:visible !important;
    max-height:75vh;
    width: 100%;
  }
  #dialogTop{
    z-index : 100;
  }
  #dialogTopImage{
    margin-top:-100px;
    text-align:center;
    &.no-image{
      height:125px;
      display: none;
    }
    img{
      width:200px;
    }

  }
  #dialogCancel{
    position: absolute;
    z-index:100;
    top: -17px;
    right: -17px;
    font-family: arial;
    font-weight: bold;
    color: #6A6A6A;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    line-height: 50px;
    font-size: 30px;
    cursor: pointer;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    &:hover{
      background-color: #f1c85b;
      color: #333533;
      box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    }
  }
  .MuiDialogTitle-root{
    margin-top: -10px;
  }
  #dialogConfirmButton{
    width:100%;
    button{
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      width:100%;
      font-size: 1.7em;
      line-height:2em;
      background-color: $successText;
      &:hover{
        background-color: #f1c85b;
      }
    }
  }
  .MuiTypography-colorTextSecondary{
    margin-bottom: 15px !important;
  }

  .MuiFormControl-root{
    margin-bottom: 20px;
    &.MuiTextField-root{
      width:100%;
      >div{
        width: 100%;
      }
    }
   
  }

  
  .action-to-take{
    text-align:center;
    font-family: $narrowHeadline;
    font-size: 1.1em;
    color: $successText !important;
    margin: 20px 40px 30px;
    padding-bottom:20px;
    border-bottom: dotted 2px white;
  }
  .page-break{
    font-size: 3em;
    padding-bottom: 10px;
    text-align:center;
    position: relative;
    svg{
      color: $darkText;
      position:absolute;
      margin-left: -24px;
      top:-24px;
    }
  }
  #onboardSteps{
    font-family: $narrowHeadline;
    background-color: #fff;
    padding:0px;
    border-radius: 8px;
    width:50%;
    margin: 0px auto;
    text-align: center;
    > div {
      padding: 20px !important;
    }
    .cta-initiation-title, .patch-detail-title{
      font-size:1.5em;
      color: $successText;
      font-family:$headline;
    }
    ol{
      text-align:left;
      li{
        line-height:1.75em;
        position:relative;
        span.checked{
          color: $successText;
          opacity: 1;
          position:absolute;
          left:-50px;
          font-size: 2em;
        }
      }

    }

  }
  .more-content{
    text-align:justify;
    line-height: 1.2em;
    > div{
      margin: 20px;
    }
  }

  #appStoreSection{
    width: 50%;
    margin: 0px auto;
    text-align:center;
    margin-top:40px;
    #storeImgContainer{
      display:flex;
      flex-direction: row;
      justify-content: center;
      a{
        margin-bottom:20px;
        img{
          height:50px;
          &#playStoreImg{
            height:40px;
            margin-top:3px;
            margin-left:20px;
            border: solid 1px white;
            border-radius: 6px;
          }
        }
      }
    }
  }
  &.dialog-template-strava-connect{
    .MuiButton-containedPrimary{
      background-color: #fff !important;
      img{
        height:100%;
      }
    }
  }

  &.dialog-template-new-level-reached{
    #secondaryMessage {
      .highlight-level{
        font-size: 1.5em;
      }
      .highlight-name{
        font-size: 1.5em;
      }
    }
    .more-content{
      text-align:center;
    }
  }
  &.dialog-template-go-to-battle{
    .more-content{
      font-family: $narrow;
      font-size: 1.1em;
      text-align: center;
    }
  }
  &.dialog-template-awaiting-battle{
    #matchupRow{
      display: flex;
      flex-direction: row;
    
      #foeImageContainer{
        text-align:center;
        img{
          max-width:200px;
          max-height:200px;
        }
        #foeText{
          span{
            font-family: $narrow;
          }
        }
      }
      #avatarText{
        span{
          font-family: $narrow;
        }
      }
      #avatar{
        position: relative;
        img#avatarImage{
          max-width: 200px;
        }
      } 
      #vs{
        text-align:center;
        > strong{
          font-size : 11vmin;
          color: $brandHighlight;
          text-shadow: 2px 2px 2px black;
          opacity:.5;
        }
        #potentialRewards{
          margin-top:5vh;
          > strong{
            font-size : 1.5rem;
            color: $lightText;
            text-shadow: initial;
            
          }
          >span{
            font-size:1rem;
            font-family: $narrow;
            opacity: .7;
            display:block;
            color: $linkText;
            text-decoration: underline;
            cursor:pointer;
            strong{
              font-size: 1rem;
              font-family: $narrowHeadline;
            }
          }
        }
      }
      .primary-row{
        flex : 1;
        align-items:center;
        justify-content: center;
        display:flex;
        flex-direction: column;
        position:relative;
        strong{
          font-size : 5vmin;
          font-family: $headline;
          line-height: 5vmin;
        }
        #questionMark{
          font-family: $headline;
          position:absolute;
          top:20%;
          strong{
            font-size : 8vmin;
            margin-left:6vmin;
          }
        }
      }
    }
  }
  &.dialog-template-the-heros-path{
    .more-content{
      text-align:center;
    }
    #onboardSteps{
      margin-bottom: 20px;
      ol{
        padding-left: 0px;
      }
    }
  }
  &.dialog-template-upgrade-hero{
    .more-content{
      text-align:center;
    }
  }
  &.dialog-template-admin-panel{
    .admin-function{
      margin-bottom:10px;
      
      padding: 5px 10px;
      &:nth-of-type(even){
        background-color: $reversedTextAlt;
      }
      &.admin-function-add-items{
        ul{
          list-style: none;
          font-size: .7em;
          display:flex;
          flex-flow: row wrap;
          li{
            padding: 5px;
            cursor: pointer;
            &:hover{
              background-color:$highlight !important;
            }
            &:nth-of-type(even){
              background-color: #ccc;
            }
          }
        }
 

      }
    }
    button{
      margin-right:10px;
      margin-left:10px;
      font-size: .7em !important;
      padding: 3px 8px !important;
    }
    strong{
      font-size:.7em;
      display:block;
    }
    label{
      font-size:.7em;
    }
    input{
      width:60px;
    }
  }
  &.dialog-template-new-patch{
    font-family:$narrowHeadline;
    p{
      font-size: .8em !important;
      color: $lightText;
    }
    #newFeatures{
      > strong{
        font-family: $headline;
        font-size: 1.2em;
      }
      li{
        margin-bottom: 20px;
      }
    }
    #linkToReddit{
      font-family: $wide;
      text-align:center;
      color: $mediumText;
    }
  }
  &.dialog-template-item-detail-codex{
    #lore p{
      text-align: left;
    }
  }
  #spendQP{
    display:flex;
    width:100%;
  
    .column{
      flex: 50%;
      width:50%;
      &:nth-of-type(1){
        padding:0px 10px 0px 0px;
      }
      &:nth-of-type(2){
        padding:0px 0px 0px 10px;
      }
      .qp-item{
        position:relative;
        height:5.5em;
        display:flex;
        flex-direction:row;
        align-items:center;
        justify-content:center;
        text-align:center;
        border-radius:14px;
        overflow:hidden;
        margin-bottom:10px;
        box-shadow: 0px 3px 3px gray;
        border: solid 1px $primaryBorder;
        max-width: 100%;
        &.qp-item-recovery{
          background: -moz-linear-gradient(top, #41a541 0%, #356735 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #41a541 0%,#356735 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #41a541 0%,#356735 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-power{
          background: -moz-linear-gradient(top, #919191 0%, #5f5f5f 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #919191 0%,#5f5f5f 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #919191 0%,#5f5f5f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */    
        }
        &.qp-item-armor{
          background: -moz-linear-gradient(top, #302f2f 0%, #242130 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #302f2f 0%,#242130 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #302f2f 0%,#242130 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-maxHealth{
          background: -moz-linear-gradient(top, #d20b22 0%, #A30216 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #d20b22 0%,#A30216 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #d20b22 0%,#A30216 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-browse-items{
          position:relative;
          z-index:1;
          overflow:hidden;
          span{
            background-color: $primaryBackground;
            padding:10px;
            border-radius:14px;
            font-weight: bold;
            opacity:.9;
            &:hover{
              color : #fff;
              background-color: $highlight;
            }
          }
  
          &:before{
            content: "";
            position: absolute;
            top: 0; 
            left: 0;
            width: 100%; 
            height: 100%;  
            opacity: .4; 
            z-index: -1;
            background: url('../../assets/images/avatars/repete/repete.webp');
            background-position:100% 5%;
          }
        }
        &.qp-item-fire{
          background: -moz-linear-gradient(top, #ff9146 0%, #fd4f11 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #ff9146 0%,#fd4f11 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #ff9146 0%,#fd4f11 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-earth{
          background: -moz-linear-gradient(top, #be6622 0%, #a53000 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #be6622 0%,#a53000 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #be6622 0%,#a53000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-water{
          background: -moz-linear-gradient(top, #00a8d4 0%, #0070a9 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #00a8d4 0%,#0070a9 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #00a8d4 0%,#0070a9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        &.qp-item-air{
          background: -moz-linear-gradient(top, #00dbfe 0%, #00b3fa 100%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, #00dbfe 0%,#00b3fa 100%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, #00dbfe 0%,#00b3fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }
        .stat{
          
        }
        .stat-button{
          width: 20%;
          button{
            border-radius: 6px;
            border:none;
            background-color: $darkBackground;
            font-size:1.5em;
            cursor:pointer;
            &:focus {
              outline:0;
            }
            &:hover, &:active{
              svg{
                color: $highlight;
              }
            }
            svg{
              color: $reversedTextAlt;
              display:block;
              padding:5px 2px;
              text-shadow: 0px 2px 2px black;
              &:before{
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
              }
            }
          }
  
        }
        .stat-increase{
          width: 15%;
          height:100%;
          background-color: $primaryBackground;
          strong{
            font-family: 'Bebas Neue', sans-serif;
            font-size:2em;
            color: $lightText;
            &:before{
              content: "";
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }
          }
        }
        .stat-name{
          width: 50%;
          text-align:left;
          padding-left:5px;
          color: #fff;
          strong{
            font-family: 'Pathway Gothic One', sans-serif;
            font-weight:100;
            font-size:1.7em;
            display:block;
          }
          p{
            font-family: 'Pathway Gothic One', sans-serif;
            font-size:.8em;
            margin:0px;
          }
          svg{
            position: absolute;
            top: 0;
            left: 60%;
            opacity: .15;
            font-size: 10vh;
          }
        }
        .stat-current{
          color:#fff;
          width: 15%;
          height: 100%;
          padding:0px;
          text-align:center;
          font-family: 'Pathway Gothic One', sans-serif;
          font-size:1.4em;
          border:none;
          font-weight:bold;
  
          &.muted{
            opacity:.7;
          }
          &.highlight{
            color: $brandHighlight !important;
          }
          &:disabled{
            background-color:rgba(0,0,0,.6);
          }
        }
      }
    }
  }

  a{
    cursor: pointer;
  }
}



@media screen and (max-height: 700px){
  .MuiDialog-root {
    .MuiDialog-paperScrollPaper {
      height: initial;
    }
  }
}

@media screen and (max-width: 599px){

  .MuiDialog-container {
    width: 90%;
    margin: 10px auto 0px;
    // .MuiDialogContent-root{
    //   max-height:55vh;
    // }
  }

  .MuiDialog-root{
    max-height:105vh;
    margin-top: 0px;
    
    .MuiDialog-paperScrollPaper{
      //height: initial !important;
      max-height: 85vh !important;
     
    }
  
    .MuiDialogActions-root {
      position: initial !important;
      bottom: initial !important;
      right: initial !important;
    }
 
 
  }

  .dialog-template-headless{
    .MuiDialog-container {
      .MuiDialogContent-root{
        max-height:initial;
      }
    }
    .MuiDialog-paperScrollPaper{

      height: initial;

    }
    &.dialog-template-item-detail-codex{
      .MuiPaper-rounded {
        .MuiDialogContent-root{
          align-items: flex-start;
        }
      }
    }
  }

  .MuiDialogTitle-root{
    text-align: center;
    margin-top: 0px !important;
  }
  .MuiTypography-colorTextSecondary{
    text-align: center;
    max-height: 30vh;
    overflow: scroll;
  }

  .dialog-template{
    &.dialog-template-basic{
      
      #dialogTop{
        height:15px;
      }
      .MuiDialog-paperScrollPaper{
        max-height: 85vh !important;
      }
      .MuiDialogContent-root{
        min-height: 50vh;
      }
    }
    .content-wrapper{
      //max-height: 50vh;
    }
    #onboardSteps{
      width:70%;
    }
    #appStoreSection{
      width: 70%;
      margin: 0px auto;
    }
    #dialogTopImage{
      margin-top:-75px;
      text-align:center;
      img{
        width:150px;
      }
  
    }
    .MuiDialog-scrollPaper{
      align-items: flex-start;
      height:initial;
    }
    .action-to-take{
      margin: 0px 12px 4px;
      padding-bottom:12px;
      font-size: .8em;
    }
    #dialogConfirmButton{
      button{
        line-height:1.5em;
      }
    }

    #spendQP{
      display:block !important;
      .stat-name{
        svg{
          font-size: 9vh!important;
        }
      }

      #spendQP{
        display:flex;
        width:100%;
      
        .column{
          flex: 50%;
          width:50%;
          &:nth-of-type(1){
            padding:0px 10px 0px 0px;
          }
          &:nth-of-type(2){
            padding:0px 0px 0px 10px;
          }
          .qp-item{
            position:relative;
            height:5.5em;
            display:flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
            text-align:center;
            border-radius:14px;
            overflow:hidden;
            margin-bottom:10px;
            box-shadow: 0px 3px 3px gray;
            border: solid 1px $primaryBorder;
            max-width: 100%;
            &.qp-item-recovery{
              background: -moz-linear-gradient(top, #41a541 0%, #356735 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #41a541 0%,#356735 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #41a541 0%,#356735 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-power{
              background: -moz-linear-gradient(top, #919191 0%, #5f5f5f 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #919191 0%,#5f5f5f 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #919191 0%,#5f5f5f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */    
            }
            &.qp-item-armor{
              background: -moz-linear-gradient(top, #302f2f 0%, #242130 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #302f2f 0%,#242130 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #302f2f 0%,#242130 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-maxHealth{
              background: -moz-linear-gradient(top, #d20b22 0%, #A30216 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #d20b22 0%,#A30216 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #d20b22 0%,#A30216 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-browse-items{
              position:relative;
              z-index:1;
              overflow:hidden;
              span{
                background-color: $primaryBackground;
                padding:10px;
                border-radius:14px;
                font-weight: bold;
                opacity:.9;
                &:hover{
                  color : #fff;
                  background-color: $highlight;
                }
              }
      
              &:before{
                content: "";
                position: absolute;
                top: 0; 
                left: 0;
                width: 100%; 
                height: 100%;  
                opacity: .4; 
                z-index: -1;
                background: url('../../assets/images/avatars/repete/repete.webp');
                background-position:100% 5%;
              }
            }
            &.qp-item-fire{
              background: -moz-linear-gradient(top, #ff9146 0%, #fd4f11 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #ff9146 0%,#fd4f11 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #ff9146 0%,#fd4f11 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-earth{
              background: -moz-linear-gradient(top, #be6622 0%, #a53000 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #be6622 0%,#a53000 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #be6622 0%,#a53000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-water{
              background: -moz-linear-gradient(top, #00a8d4 0%, #0070a9 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #00a8d4 0%,#0070a9 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #00a8d4 0%,#0070a9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            &.qp-item-air{
              background: -moz-linear-gradient(top, #00dbfe 0%, #00b3fa 100%); /* FF3.6-15 */
              background: -webkit-linear-gradient(top, #00dbfe 0%,#00b3fa 100%); /* Chrome10-25,Safari5.1-6 */
              background: linear-gradient(to bottom, #00dbfe 0%,#00b3fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            }
            .stat{
              
            }
            .stat-button{
              width: 20%;
              button{
                border-radius: 6px;
                border:none;
                background-color: $darkBackground;
                font-size:1.5em;
                cursor:pointer;
                &:focus {
                  outline:0;
                }
                &:hover, &:active{
                  svg{
                    color: $highlight;
                  }
                }
                svg{
                  color: $reversedTextAlt;
                  display:block;
                  padding:5px 2px;
                  text-shadow: 0px 2px 2px black;
                  &:before{
                    content: "";
                    display: inline-block;
                    height: 100%;
                    vertical-align: middle;
                  }
                }
              }
      
            }
            .stat-increase{
              width: 15%;
              height:100%;
              background-color: $primaryBackground;
              strong{
                font-family: 'Bebas Neue', sans-serif;
                font-size:2em;
                color: $lightText;
                &:before{
                  content: "";
                  display: inline-block;
                  height: 100%;
                  vertical-align: middle;
                }
              }
            }
            .stat-name{
              width: 50%;
              text-align:left;
              padding-left:5px;
              color: #fff;
              strong{
                font-family: 'Pathway Gothic One', sans-serif;
                font-weight:100;
                font-size:1.7em;
                display:block;
              }
              p{
                font-family: 'Pathway Gothic One', sans-serif;
                font-size:.8em;
                margin:0px;
              }
              svg{
                position: absolute;
                top: 0;
                left: 60%;
                opacity: .15;
                font-size: 10vh;
              }
            }
            .stat-current{
              color:#fff;
              width: 15%;
              height: 100%;
              padding:0px;
              text-align:center;
              font-family: 'Pathway Gothic One', sans-serif;
              font-size:1.4em;
              border:none;
              font-weight:bold;
      
              &.muted{
                opacity:.7;
              }
              &.highlight{
                color: $brandHighlight !important;
              }
              &:disabled{
                background-color:rgba(0,0,0,.6);
              }
            }
          }
        }
      }
    }

    &.dialog-template-speech{
      div.MuiDialog-container{
        div[role="dialog"]{
          position:absolute;
          top: 70px;
        }
        .MuiDialogContent-root{
          padding: 0px !important;
        }
      }
      .MuiDialogContentText-root{
        padding:10px;
      }
      .MuiDialog-paperScrollPaper{
        max-height: 85vh !important;
      }
    }

    &.dialog-template-awaiting-battle{
      .more-content > div{
        margin: 0px;
      }
      #matchupRow{
        flex-direction: row;
        #foeImageContainer{
          img{
            max-width:150px;
            max-height:150px;
          }
        }
        #avatar{
          img#avatarImage{
            max-width: 150px;
          }
        } 
        #vs {
          > strong{
            padding:30px 0px 0px;
            font-size: 10vmin;
          }
          #potentialRewards{
            margin-top:initial;
            > strong{
              font-size : 1rem;
              line-height: 1rem;
              margin: 0px;
              padding: 0px;
              color: $lightText;
              text-shadow: initial;
            }
            span{
              font-size:.7rem;
              font-family: $narrow;
  
              strong{
                font-size: .7rem;
                font-family: $narrowHeadline;
              }
              &.reward-type{
                display:none;
              }
            }
          }
        }
        
      }
    }

    &.dialog-template-select-hero{
      .MuiDialog-scrollPaper{
        align-items: flex-start;
        height:initial;
      }
      .avatar_hex{
        z-index: 0;
      }
    }

    &.dialog-template-spend-qp{
      #dialogTop {
        z-index: 1000;
      }
    }
  }
}

// Very small phones form fix
@media screen and (max-width: 400px) and (max-height: 725px){
  .MuiDialogTitle-root {
    padding: 0px 6px !important;
  }
  .MuiDialogContent-root{
    padding: 2px 6px !important;
  }
  form{
    font-size: .7em !important;
    .input-container{
      margin-bottom: 0 !important;
      input{
        font-size: .7em !important;
      }
    }
    label{
      strong{
        font-size: .7em !important;
      }
    }
    
  }
}


// Small Phones Modal fix
@media (max-height: 640px) and (max-width: 510px){
  .dialog-template{
    &.dialog-template-speech{
      .MuiDialog-paperScrollPaper{
        max-height: 75vh !important;
      }
    }
    &.dialog-template-register{
      form{
        font-size: .8em !important;
        .input-container{
          margin-bottom:1em;
          input{
            font-size: .8em !important;
          }
        }
        label{
          strong{
            font-size: .75em !important;
          }
        }
      }
    }
  }
}