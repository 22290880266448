@import '../../assets/styles/variables';

#accountPage{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items: center;
  #formContainer{
    width:60%;
    min-height:500px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    background-color: #fff;
    padding: 20px;
    margin: 20px;
    form{
      width:100%;
      max-width:400px;
      display:block;
      position:relative;
      margin-bottom:8vmin;
      padding-bottom:8vmin;
      background-color:$secondaryBackground;
      padding: 20px;
    }
    div{
      &#stravaButtonWrapper{
        display:flex;
        flex-direction: row;
        align-items: space-between;
        justify-content: space-between;
        margin-top:20px;
        button{
  
          width: 45%;
        }
      }
      &:nth-of-type(2){
        background-color:black;
      }
      .MuiInput-root{
        width: 100%;
        margin-bottom:2vh;
      }
      label{
        text-align:left;
        margin-bottom:1vh;
      }
      &:last-of-type{
        //border-bottom: none;
        text-align : center;
        
        label{
          margin-bottom: 2vmin;
        }
        button{
          width:100%;
        }
      }
    }
    #deleteAccount button{
      background-color: $warning;
    }
    #messageWrapper{
      min-height:40px;
      width:100%;
      #heroNameHelperText, #checkingMessage{
        text-align:center;
        padding:10px;
      }
    }
    #updateAvatarName{
      margin-bottom:20px;
    }
  }
}

@media screen and (max-height:700px){
  #messageWrapper{
    font-size:.8em;
  }
}