@import '../../../assets/styles/variables.scss';

.dialog-template-shop-items{
  
  .MuiDialogTitle-root h2{
    border-bottom: solid 1px $darkText;
    padding-bottom: 5px;
    position:relative;
    span{
      display:inline-block;
    }
    .pt-count{
      position: absolute;
      right:.25em;
      top:-.25em;
      color: $brandHighlight;
      img{
        width: 35px;
      }
    }
  }
  .MuiDialogTitle-root h5{
  
  }
  .MuiTypography-colorTextSecondary{
    #lore{
      //background: $secondaryBackground;
      color:$mediumText;
      border-radius:2px;
      padding: 5px;
      font-family: $cursiveText;
      font-size: 1.2rem;
    }
  }
  .effect-properties{
    ul{
      margin:0px;
      padding:0px;
      li{
        border-radius: 8px;
        padding: 5px;
        margin-bottom: 0px;
        &:last-of-type(){
          margin-bottom:0px;
        }
      }
      h5{
        font-family: $headline;
        color: $mediumText;
        font-size:1.1rem;
        padding:0px;
        margin:0px 0px 10px 0px;
      }
      strong{
        font-size: 1.1rem;
        text-transform: capitalize;
        color: $lightText;
      }
      p{
        font-family: $narrowHeadline;
        font-size: .9rem !important;
        line-height: 1.3rem;
        color: $mediumText !important;
        opacity:.6;
      }
    }
  }
  .item-row{
    padding: 10px;
    cursor: pointer;
    position:relative;
    &:nth-of-type(even){
      background-color: $secondaryBackground;
    }
    &.selected-item{
      background-color: $highlight;
    }
    &.not-for-sale{
      cursor : default;
      .primary-details{
        opacity: .2;
      }
      &:after{
        font-weight:bold;
        font-family: $headline;
        font-size: 1.5em;
        color: $mediumText;
        background-color: $primaryBackground;
        border-radius: 6px;
        padding: 10px;
        content: "Not For Sale";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

      }
    }
    .primary-details{
      display:flex;
      align-items: center;
      justify-content: center;
      text-align:center;
      .item-image-wrapper{
        img{
          width: 100px;
        }
      }
      ul{
        list-style: none;
      }
      > div{
        width:100%;
        color: $lightText;
        font-family: $narrowHeadline;
      }
      .item-description{
        font-weight: bold;
      }
    }
  }
  h4{
    font-size: 1rem;
    font-family: $headline;
    color: $mediumText;
    margin:5px;
  }
  #content{
    border: solid 3px $secondaryBackground;
    border-radius: 8px;
  }
  
}

@media screen and (max-width:720px){
  .dialog-template-shop-items {
    .MuiDialogTitle-root{
      text-align:left;
    }
    .primary-details{
      flex-direction:column;
      #itemImageContainer{
        text-align:center;
      }
    }
    #itemImage{
      
      width:140px;
    }
    .MuiTypography-colorTextSecondary{
      #lore{
        line-height: 1rem;
      }
    }
    h4{
      font-size: 1.2rem !important;
    }
    #primaryRow p,  #secondaryRow p{
      font-size: 1.2rem !important;
    }
  }
}