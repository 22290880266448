@import "../../assets/styles/variables.scss";

#foePage {
 #villainContainer {
  font-family: $narrow;
  background-color: #fff;
  #legend {
   padding: 20px 20px 0px;
   .light-link-text {
    text-decoration: underline;
   }
  }
  .villain-class {
   padding: 0px 20px 20px;
   h3 {
    font-family: $headline;
    font-size: 2em;
    color: $reversedTextAlt;
    background-color: #000;
    padding: 10px 20px 5px;
   }
   > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    justify-content: space-between;
    li.villain-type {
     //background-color: #fff;
     padding: 10px;
     min-width: 225px;
     min-height: 250px;
     position: relative;
     h4 {
      font-size: 1.5em;
      margin: 0px;
      z-index: 1;
      position: relative;
     }
     h5 {
      padding: 0px;
      margin: 0px;
      margin-top: -5px;
      margin-bottom: 10px;
     }
     ul {
      position: relative;
      z-index: 1;
      justify-content: space-between;
      padding-left: 15px;
      list-style-position: inside;
     }
     .ability-text,
     .reward-text {
      z-index: 1;
      position: relative;
      display: block;
      max-width: 250px;
     }
     .reward-text {
      cursor: pointer;
      text-decoration: underline;
     }
     .villain-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      background-size: 55%;
      background-repeat: no-repeat;
      background-position: right bottom;

      &.shade-background {
       background-image: url("../../assets/images/foes/spirits/shade/shade.webp");
      }
      &.wraith-background {
       background-image: url("../../assets/images/foes/spirits/wraith/wraith.webp");
      }
      &.phantasm-background {
       background-image: url("../../assets/images/foes/spirits/phantasm/phantasm.webp");
      }
      &.banshee-background {
       background-image: url("../../assets/images/foes/spirits/banshee/banshee.webp");
      }
      &.phantom-background {
       background-image: url("../../assets/images/foes/spirits/phantom/phantom.webp");
      }
      &.specter-background {
       background-image: url("../../assets/images/foes/spirits/specter/specter.webp");
      }
      &.poltergeist-background {
       background-image: url("../../assets/images/foes/spirits/poltergeist/poltergeist.webp");
      }
      &.apparition-background {
       background-image: url("../../assets/images/foes/spirits/apparition/apparition.webp");
      }
      &.shadow_boulder_bro-background {
       background-image: url("../../assets/images/avatars/boulder_bro/shadow_boulder_bro.webp");
       background-size: 65%;
      }
      &.shadow_chrono_guy-background {
       background-image: url("../../assets/images/avatars/chrono_guy/shadow_chrono_guy.webp");
       background-size: 65%;
      }
      &.shadow_compost_creature-background {
       background-image: url("../../assets/images/avatars/compost_creature/shadow_compost_creature.webp");
       background-size: 65%;
      }
      &.shadow_empath_aurelia-background {
       background-image: url("../../assets/images/avatars/empath_aurelia/shadow_empath_aurelia.webp");
      }
      &.shadow_filtron_five-background {
       background-image: url("../../assets/images/avatars/filtron_five/shadow_filtron_five.webp");
       background-size: 65%;
      }
      &.shadow_natural_ninja-background {
       background-image: url("../../assets/images/avatars/natural_ninja/shadow_natural_ninja.webp");
       background-size: 65%;
      }
      &.shadow_repete-background {
       background-image: url("../../assets/images/avatars/repete/shadow_repete.webp");
       background-size: 65%;
      }
      &.shadow_solar_celeste-background {
       background-image: url("../../assets/images/avatars/solar_celeste/shadow_solar_celeste.webp");
       background-size: 65%;
      }
      &.shadow_timber_terror-background {
       background-image: url("../../assets/images/avatars/timber_terror/shadow_timber_terror.webp");
       background-size: 65%;
      }
      &.shadow_wilhelm_the_wild-background {
       background-image: url("../../assets/images/avatars/wilhelm_the_wild/shadow_wilhelm_the_wild.webp");
       background-size: 65%;
      }
      &.plaguebringer-background {
       background-image: url("../../assets/images/foes/titans/plaguebringer.webp");
       background-size: 85%;
      }
      &.guardian_of_the_depths-background {
       background-image: url("../../assets/images/foes/titans/guardian_of_the_depths.webp");
       background-size: 75%;
      }
      &.gusty_rascal-background {
       background-image: url("../../assets/images/foes/elementals/gusty_rascal.webp");
       background-size: 75%;
      }
      &.rock_skipper-background {
       background-image: url("../../assets/images/foes/elementals/rock_skipper.webp");
       background-size: 75%;
      }
      &.flame_fiend-background {
       background-image: url("../../assets/images/foes/elementals/flame_fiend.webp");
       background-size: 75%;
      }
      &.splash_artist-background {
       background-image: url("../../assets/images/foes/elementals/splash_artist.webp");
       background-size: 75%;
      }
      &.wheezing_jinn-background {
       background-image: url("../../assets/images/foes/elementals/wheezing_jinn.webp");
       background-size: 75%;
      }
      &.granite_golem-background {
       background-image: url("../../assets/images/foes/elementals/granite_golem.webp");
       background-size: 75%;
      }
      &.burning_jinn-background {
       background-image: url("../../assets/images/foes/elementals/burning_jinn.webp");
       background-size: 75%;
      }
      &.cyclonic_siren-background {
       background-image: url("../../assets/images/foes/elementals/cyclonic_siren.webp");
       background-size: 75%;
      }
      &.storming_oni-background {
       background-image: url("../../assets/images/foes/elementals/storming_oni.webp");
       background-size: 75%;
      }
      &.hulking_aggro_crag-background {
       background-image: url("../../assets/images/foes/elementals/hulking_aggro_crag.webp");
       background-size: 75%;
      }
      &.scorching_archfiend-background {
       background-image: url("../../assets/images/foes/elementals/scorching_archfiend.webp");
       background-size: 75%;
      }
      &.high_priestess_of_the_tides-background {
       background-image: url("../../assets/images/foes/elementals/high_priestess_of_the_tides.webp");
       background-size: 75%;
      }
     }
     .engage-with-item-container {
      position: absolute;
      bottom: 0px;
      font-size: 0.8em;
      text-align: center;
      z-index: 100;
      background-color: $primaryBackground;
      width: 100%;
      opacity: 0.7;
     }
    }
   }
  }
 }
 #loadingContainer {
  background-image: url("../../assets/images/layout/desktop-loading.webp");
  background-size: cover;
  width: 100%;
  height: 100%;
 }
}

@media screen and (max-width: 720px) {
 #foePage {
  #loadingContainer {
   background-image: url("../../assets/images/layout/mobile-loading.webp");
  }
 }
}
