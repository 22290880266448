@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/animations.scss";

.modal-overlay{
  position:absolute;
  z-index:1;
  display:flex;
  align-items:center;
  justify-content:center;
  top:0px;
  left:0px;
  width:100%;
  height:100%;
  //opacity:.8;
  background-color: #fff;

  &#loadingModalOverlay{
    background-image : url('../../../assets/images/layout/mobile-loading.webp');
    background-size:cover;
    background-repeat:no-repeat;
    background-position:bottom;
  }
  &.hide{
    display:none;
  }
  #loadingModalIndicatorContainer, #successModalContainer{
    width:100%;
    height:100%;
    font-family: 'Pragati Narrow';
    font-size:3em;
    font-weight:bold;
    text-align:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    color: $lightText;
    #loadingModalIndicatorWrapper, #successModalIconWrapper{

      width:20vh;
      height:20vh;
      margin-bottom:40px;
      .MuiCircularProgress-root{
        &.MuiCircularProgress-colorPrimary{
          color:$reversedLinkText;
        }
      }
    }

  }
  &#successModalOverlay{
    animation: FadeIn ease .6s;
    #successModalOverlayMessage{
      font-size:.7em;
      color: $mediumText;
    }
  }
}
