@import "../../assets/styles/variables.scss";
@import "../../assets/styles/animations.scss";

#InventoryPage {
  > div {
    h2.section-title {
      font-family: $headline;
      font-size: 3em;
      color: $brandHighlight;
      padding: 25px;
      margin: 0px;
      position: relative;
      span{
        display:inline-block;
      }
      .pt-count{
        position: absolute;
        right:25px;
        top:0px;
       
        img{
          width: 8vh;
        }
      }
    }
    h3.subsection-title {
      font-family: $narrowHeadline;
      font-size: 2.5em;
      color: $mediumText;
      padding: 0px;
      margin: 0px 0px 10px 0px;
      position: relative;
      .shop-link{
        position: absolute;
        right: 0px;
        &:hover{
          color: $highlight;
        }
      }
    }
    #equippedSection {
      background-color: $darkBackground;
      border-bottom: solid 5px $brandHighlight;
      border-top: solid 5px $brandHighlight;
      padding-bottom: 40px;
      .equipped-title {
        margin-bottom: 20px;
        cursor: pointer;
        strong {
          display: block;
          font-family: $narrowHeadline;
          text-transform: uppercase;
          font-size: 1.5em;
          color: white;
        }
        span {
          font-family: $narrowHeadline;
          color: $brandHighlight;
        }
      }

      > div {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        padding: 20px;
        #primary {
          width: 45%;
          min-width: 500px;
          margin-bottom: 40px;
          #equippedCostume {
            width: 500px;
            height: 500px;
            max-height: 500px;
            max-width: 500px;
            cursor: pointer;
            &:before {
              content: "";
              position: absolute;
              left: 50%;
              top: 50%;
              border-radius: 50%;
              background-color: #fff;
              box-shadow: 0 0 60px 30px #fff,
                /* inner white */ 0 0 100px 60px #d4af37,
                /* middle gold */ 0 0 300px 90px ivory; /* outer ivory */
            }
            img{
              position:absolute;
            }
          }
        }
        #secondary {
          width: 45%;
          min-width: 500px;
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          align-items: center;
          #equippedTitle, #equippedPet{
            cursor: pointer;
          }
          #equippedPet{
            cursor: pointer;
          }
          > div {
            flex: 1 0 auto;
          }
          .secondary-equipped {
            .secondary-content-container {
              margin-top: 20px;
              position: relative;
              width: 200px;
              height: 200px;
              text-align: center;
              display: flex;
              justify-content: center;
              align-items: center;
              > img {
                max-width: 90%;
                margin-top: 5%;
              }
              .svg-arrow-border {
                left: 0px;
                top: 0px;
                img {
                  width: 33%;
                  height: 33%;
                }
              }
            }
          }
        }
      }
    }
    #inventorySection {
      .subsection {
        background-color: $primaryBackground;
        padding: 20px 40px;
        .subsection-content-container {
          display: flex;
          flex-flow: row wrap;
          background-color: #fff;
          padding: 20px;
          .row-item {
            position: relative;
            cursor: pointer;
            margin-right: 20px;

            .row-item-content-container {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 150px;
              height: 150px;
              position: relative;
              margin: 0px auto;
              .count-container{
                border-radius: 50%;
                width: 35px;
                height: 35px;
                position: absolute;
                bottom: 0px;
                right: 0px;
                border: solid 3px $lightText;
                color: $lightText;
                font-family: $headline;
                font-size: 25px;
                strong{
                  display:block;
                  line-height: 35px;
                }
              }
              &:hover{
                background-color: $highlight;
              }
              > img {
                position: absolute;
                z-index: 1;
                width: 130px;
              }
              .none-square {
                background-color: $secondaryBackground;
                color: $mediumText;
                font-family: $headline;
                font-size: 1.5em;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .svg-arrow-border {
                img {
                  width: 33%;
                  height: 33%;
                }
              }
            }
            .row-item-title, .row-item-consumable {
              padding: 10px;
              margin-top: 10px;
              min-height: 60px;
              font-family: $narrowHeadline;
              font-size: 1.5em;
              color: $mediumText;
              width: 150px;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
              flex-direction: column;
              span {
                font-size: 1.1rem;
              }
            }
            .svg-arrow-border {
              display: none;
              z-index: 0;
              top: 0px;
              left: 0px;
            }
            &.row-item-pet {
              .row-item-content-container {
                width: 130px;

                > img {
                  position: relative;
                  width: 130px;
                  z-index: 1;
                  margin-top: 10px;
                }
              }
            }
            &:last-of-type {
              margin-right: 0;
            }
            &.active {
              .svg-arrow-border {
                display: block;

                img:nth-of-type(1) {
                  animation: contractExpand1 2.5s ease-in-out infinite;
                }
                img:nth-of-type(2) {
                  animation: contractExpand2 2.5s ease-in-out infinite;
                }
                img:nth-of-type(3) {
                  animation: contractExpand3 2.5s ease-in-out infinite;
                }
                img:nth-of-type(4) {
                  animation: contractExpand4 2.5s ease-in-out infinite;
                }
              }
              .row-item-title {
                background-color: $brandHighlight;
              }
            }
          }
          .none-of-type {
            width: 100%;
            font-family: $narrowHeadline;
            text-transform: uppercase;
            text-align: center;
            font-size: 1.5em;
          }
        }
      }
    }
  }
  .square {
    position: relative;
  }
  .svg-arrow-border {
    position: absolute;
    width: 100%;
    height: 100%;
    img {
      position: absolute;
      &:nth-of-type(1) {
        left: 0px;
        top: 0px;
      }
      &:nth-of-type(2) {
        right: 0px;
        top: 0px;
        transform: rotate(90deg);
      }
      &:nth-of-type(3) {
        right: 0px;
        bottom: 0px;
        transform: rotate(180deg);
      }
      &:nth-of-type(4) {
        left: 0px;
        bottom: 0px;
        transform: rotate(270deg);
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  #InventoryPage {
    > div {
      #equippedSection {
        > div {
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 599px) {
  #InventoryPage {
    > div {
      display: flex;
      flex-direction: column-reverse;
      #equippedSection > div {
        flex-direction: column;
        #primary {
          width: 100%;
          min-width: 0;
          margin-bottom: 40px;
          #equippedCostume {
            width: 300px;
            height: 300px;
            margin: 0px auto;
            > img{
              width: 100%;
              height: initial;
            }
          }
          .svg-arrow-border {
            img {
              width: 33%;
              height: 33%;
            }
          }
        }
        #secondary {
          width: 100%;
          min-width: initial;
          .secondary-equipped {
            .secondary-content-container {
              width: 125px;
              height: 125px;
              margin: 20px auto;
            }
          }
        }
      }
      #inventorySection,
      #titlesSubsection,
      #petsSubsection {
        .subsection {
          padding: 0px;
        }
      }
      #inventorySection {
        .subsection-content-container {
          padding: 10px !important;
          justify-content: space-between;
          .row-item{
            margin-right: 0px !important;
            padding-bottom: 20px;
            .row-item-content-container, .row-item-title{
              width: 130px !important;
            }
          }
        }
      }

      h2.section-title{
        .pt-count{
          position: absolute;
          right:25px;
          top:25px;
          font-size:2rem;
          img{
            width: 6vh;
          }
        }
      }
      h3.subsection-title{
        padding:0px 20px;
        .shop-link{
          right: 20px;
        }
      }
    }
  }
}
