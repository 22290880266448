@import '../../../assets/styles/variables.scss';

.dialog-template-item-detail{
  .MuiDialogTitle-root h2{
    border-bottom: solid 1px $darkText;
    padding-bottom: 5px;
  }
  .MuiTypography-colorTextSecondary{
    #lore{
      color:$mediumText;
      border-radius:2px;
      padding: 5px;
      font-family: $cursiveText;
      font-size: 1.2rem;
    }
  }
  .effect-properties{
    ul{
      margin-left:10px;
      padding:0px 5px;
      li{
        background-color: $secondaryBackground;
        border-radius: 8px;
        padding: 5px;
        margin-bottom: 10px;
        &:last-of-type(){
          margin-bottom:0px;
        }
      }
      h5{
        font-family: $headline;
        color: $mediumText;
        font-size:1.1rem;
        padding:0px;
        margin:0px 0px 10px 0px;
      }
      strong{
        font-size: 1.1rem;
        text-transform: capitalize;
        color: $lightText;
      }
      p{
        font-family: $narrowHeadline;
        font-size: 1rem !important;
        line-height: 1.5rem;
        color: $mediumText !important;
        opacity:.8;
      }
    }
  }

  #primaryRow{
    display:flex;
    flex-direction:row;
    align-items: stretch;
    font-family: $narrowHeadline;
    .col{
      min-width: 25%;
      &.col-details{
        .item-type{
          text-transform: capitalize;
        }
        p{
          font-size: 1.4rem;
          font-family: $narrow;
          margin: 5px auto 15px;
          color: $lightText;
        }
      }
      &.col-image{
        flex-grow: 2;
        text-align:right;
        position: relative;
      }
    }
  }
  #secondaryRow{
    font-family: $narrowHeadline;
    p{
      font-size: 1.4rem;
      font-family: $narrow;
      margin: 5px auto 15px;
      color: $lightText;
    }
  }
  h4{
    font-size: 1.4rem;
    border-bottom: solid 1px $darkText;
    display:inline-block;
    margin-bottom:0px;
    margin-top:5px;
    color: $mediumText;
  }
  #content{
    border: solid 3px $secondaryBackground;
    padding: 10px;
    border-radius: 8px;
  }
  
}

.dialog-template-item-detail-codex{
  .MuiDialog-paper{
    background-image: url('../../../assets/images/misc/codex-background.jpg');
    p:first-child:first-letter{
      float: left;
      color: $mediumText;
      font-size: 75px;
      line-height: 60px;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 3px;
      font-family: $cursiveText;
    }
  }
  .MuiTypography-colorTextSecondary{
    max-height:none !important;
    background-image: url('../../../assets/images/misc/codex-background-light.jpg');
    background-attachment: scroll;
    overflow: visible;
    padding:10px;
    box-shadow: 0px 4px 8px black;
 
    #lore{
      color:$darkText;
      font-family: $narrow;
      font-size: 1.2rem;
    }
    
  }
  .content-wrapper{
    display:none;
  }
  .MuiDialogActions-root{
    display:none;
  }
}

@media screen and (max-width:720px){
  .dialog-template-item-detail {
    #primaryRow{
      flex-direction:column-reverse;
      #itemImageContainer{
        text-align:center;
      }
    }
    #itemImage{
      
      width:140px;
    }
    .MuiTypography-colorTextSecondary{
      #lore{
        line-height: 1rem;
      }
    }
    h4{
      font-size: 1.2rem !important;
    }
    #primaryRow p,  #secondaryRow p{
      font-size: 1.2rem !important;
    }
  }
}