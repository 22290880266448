@import '../../../../assets/styles/variables';

#spendQP{
  display:flex;
  width:100%;

  .column{
    flex: 50%;
    width:50%;
    &:nth-of-type(1){
      padding:0px 10px 0px 0px;
    }
    &:nth-of-type(2){
      padding:0px 0px 0px 10px;
    }
    .qp-item{
      position:relative;
      height:5.5em;
      display:flex;
      flex-direction:row;
      align-items:center;
      justify-content:center;
      text-align:center;
      border-radius:14px;
      overflow:hidden;
      margin-bottom:10px;
      box-shadow: 0px 3px 3px gray;
      border: solid 1px $primaryBorder;
      max-width: 100%;
      &.qp-item-recovery{
        background: -moz-linear-gradient(top, #41a541 0%, #356735 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #41a541 0%,#356735 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #41a541 0%,#356735 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-power{
        background: -moz-linear-gradient(top, #919191 0%, #5f5f5f 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #919191 0%,#5f5f5f 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #919191 0%,#5f5f5f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */    
      }
      &.qp-item-armor{
        background: -moz-linear-gradient(top, #302f2f 0%, #242130 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #302f2f 0%,#242130 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #302f2f 0%,#242130 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-maxHealth{
        background: -moz-linear-gradient(top, #d20b22 0%, #A30216 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #d20b22 0%,#A30216 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #d20b22 0%,#A30216 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-browse-items{
        position:relative;
        z-index:1;
        overflow:hidden;
        span{
          background-color: $primaryBackground;
          padding:10px;
          border-radius:14px;
          font-weight: bold;
          opacity:.9;
          &:hover{
            color : #fff;
            background-color: $highlight;
          }
        }

        &:before{
          content: "";
          position: absolute;
          top: 0; 
          left: 0;
          width: 100%; 
          height: 100%;  
          opacity: .4; 
          z-index: -1;
          background: url('../../../../assets/images/avatars/repete/repete.webp');
          background-position:100% 5%;
        }
      }
      &.qp-item-fire{
        background: -moz-linear-gradient(top, #ff9146 0%, #fd4f11 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #ff9146 0%,#fd4f11 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #ff9146 0%,#fd4f11 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-earth{
        background: -moz-linear-gradient(top, #be6622 0%, #a53000 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #be6622 0%,#a53000 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #be6622 0%,#a53000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-water{
        background: -moz-linear-gradient(top, #00a8d4 0%, #0070a9 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #00a8d4 0%,#0070a9 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #00a8d4 0%,#0070a9 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-air{
        background: -moz-linear-gradient(top, #00dbfe 0%, #00b3fa 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #00dbfe 0%,#00b3fa 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #00dbfe 0%,#00b3fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
      &.qp-item-aether{
        background: -moz-linear-gradient(top, #ffffff 0%, #FFFFC2 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #ffffff 0%,#FFFFC2 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #ffffff 0%,#FFFFC2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        .stat-name{
          color : $darkText;
        }
      }

      .stat-button{
        width: 20%;
        button{
          border-radius: 6px;
          border:none;
          background-color: $darkBackground;
          font-size:1.5em;
          cursor:pointer;
          &:focus {
            outline:0;
          }
          &:hover, &:active{
            svg{
              color: $highlight;
            }
          }
          svg{
            color: $reversedTextAlt;
            display:block;
            padding:5px 2px;
            text-shadow: 0px 2px 2px black;
            &:before{
              content: "";
              display: inline-block;
              height: 100%;
              vertical-align: middle;
            }
          }
        }

      }
      .stat-increase{
        width: 15%;
        height:100%;
        background-color: $primaryBackground;
        strong{
          font-family: 'Bebas Neue', sans-serif;
          font-size:2em;
          color: $lightText;
          &:before{
            content: "";
            display: inline-block;
            height: 100%;
            vertical-align: middle;
          }
        }
      }
      .stat-name{
        width: 50%;
        text-align:left;
        padding-left:5px;
        color: #fff;
        strong{
          font-family: 'Pathway Gothic One', sans-serif;
          font-weight:100;
          font-size:1.7em;
          display:block;
        }
        p{
          font-family: 'Pathway Gothic One', sans-serif;
          font-size:.8em;
          margin:0px;
        }
        svg{
          position: absolute;
          top: 0;
          left: 60%;
          opacity: .15;
          font-size: 10vh;
        }
      }
      .stat-current{
        color:#fff;
        width: 15%;
        height: 100%;
        padding:0px;
        text-align:center;
        font-family: 'Pathway Gothic One', sans-serif;
        font-size:1.4em;
        border:none;
        font-weight:bold;

        &.muted{
          opacity:.7;
        }
        &.highlight{
          color: $brandHighlight !important;
        }
        &:disabled{
          background-color:rgba(0,0,0,.6);
        }
      }
    }
  }
}

@media screen and (max-width:599px){
  #spendQP{
    display:block !important;
    .stat-name{
      svg{
        font-size: 9vh!important;
      }
    }
  }
}