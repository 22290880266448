@import "../../assets/styles/variables.scss";

.table th{
  &:hover{
    opacity: 1;
    color: $mediumText;
  }
}

.table-container{
  overflow:scroll;
  color: $mediumText;
  svg{
    color: $mediumText;
    path{
      stroke: $mediumText;
    }
  }
}

.need-content{
  display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  font-family: $narrow;
  font-size: 1.5em;
  > div{
    height:100%;
    margin-top: 100px;
    .strava-highlight{
      cursor:pointer;
    }
  }
}

#viewOnStravaLink{
  text-align:center;
  padding:40px;
  a{
    color:$stravaHighlight;
  }
}

#latestActivityTable{
  th{
    cursor: default !important;
    opacity: 1 !important;
  }
}