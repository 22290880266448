@import "../../assets/styles/variables.scss";
@import "../../assets/styles/animations.scss";

#oauthPage {
 position: relative;
 justify-content: center;
 align-items: flex-start;
 text-align: center;
 min-height: 700px;
 width: 100%;
 background-image: url("../../assets/images/layout/mobile-loading.webp");
 background-size: cover;
 background-repeat: no-repeat;
 background-position-x: center;
 background-position-y: bottom;
 display: flex;
 #oauthPageContent {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  color: $reversedText;

  h1 {
   font-size: 10em;
   margin: 0px;
   font-family: "Bebas Neue", Helvetica, Arial, sans-serif;
   text-shadow: 5px 5px $darkText;
   color: $brandHighlight;
  }
  h2 {
   margin-top: -1em;
   color: $darkText;
   font-family: "Pathway Gothic One", sans-serif;
   font-size: 2em;
  }
  #titleContainer {
   display: block;
   h1 {
    font-size: 8em;
    margin: 10vmin 0vmin;
   }
   h2 {
    margin-top: -2em;
   }
  }
  #successContainer {
   background-color: $primaryBackground;
   color: $mediumText;
  }
 }

 #welcomePageOverlay {
  z-index: 0;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  background-color: $darkBackground;
 }
 p {
  margin-top: 0px;
  margin-bottom: 3em;
  line-height: 1.5em;
 }
}
