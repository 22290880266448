@import "../../assets/styles/variables.scss";
@import "../../assets/styles/animations.scss";
footer {
 position: relative;
 border-top: solid 5px $brandHighlight;
 height: 280px;
 text-align: center;
 color: $reversedText;
 background-color: $darkBackground;

 > #footerContent {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  .social-sharing-profile {
   text-align: center;
   margin-bottom: 10px;
   button {
    margin-right: 5px;
    &:last-of-type {
     margin-right: initial;
    }
   }
  }
  > div {
   position: relative;
   z-index: 10;
   > strong {
    white-space: nowrap !important;
    display: block;
    color: $brandHighlight;
    font-family: $headline;
    font-size: 1.5rem;
    margin-bottom: 10px;
   }
   a {
    color: $brandHighlight;
    font-size: 0.8em;
   }
  }
  #footerBackgroundImage {
   background-image: url("../../assets/images/misc/herofit-logo.webp");
   background-position: center;
   background-size: 440px;
   background-repeat: no-repeat;
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0.3;
   z-index: 0;
  }
 }
 #alertWrapper {
  position: fixed;
  z-index: 9999;
  bottom: 0px;
  width: 50%;
  .alert-section {
   text-align: left;
   padding: 0px 20px 20px 20px;
   .alert {
    position: relative;
    margin-top: 10px;

    opacity: 0.9;
    .alert-title {
     text-transform: uppercase;
    }
    .close-alert {
     position: absolute;
     top: 10px;
     right: 10px;
     font-size: 1.5em;
     cursor: pointer;
    }
    &.fade-in {
     animation: fadeIn ease 2s;
     -webkit-animation: fadeIn ease 2s;
     -moz-animation: fadeIn ease 2s;
     -o-animation: fadeIn ease 2s;
     -ms-animation: fadeIn ease 2s;
    }
    &.fade-out {
     animation: fadeOut ease 1s;
     -webkit-animation: fadeOut ease 1s;
     -moz-animation: fadeOut ease 1s;
     -o-animation: fadeOut ease 1s;
     -ms-animation: fadeOut ease 1s;
    }
    &.fade-in-then-out {
     animation: fadeInThenOut ease 10s;
     -webkit-animation: fadeInThenOut ease 10s;
     -moz-animation: fadeInThenOut ease 10s;
     -o-animation: fadeInThenOut ease 10s;
     -ms-animation: fadeInThenOut ease 10s;
    }
    &:hover {
     opacity: 1;
    }
   }
   button {
    cursor: pointer;
   }
  }
 }
}

@media screen and (max-width: 729px) {
 footer {
  #alertWrapper {
   width: 100%;
  }
  #footerContent {
   p {
    width: 100%;
   }
   span {
    line-height: 2em;
    display: block;
    margin-top: 6vw;
    font-size: 1em;
   }
  }
  #footerBackgroundImage {
   background-size: 340px !important;
  }
 }
}
