#notFoundPage{
  &>div{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items:center;
    min-height:500px;
    a{
      margin-top:2em;
      font-size:2em;
    }
  }
}