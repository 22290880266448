#contactPage{
  min-height:70vh;
  z-index:100;
  position:relative;
  .form-section{
    position:relative;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content:center;
    z-index:10;
    width: 100%;
    height:100%;
    min-height:50vh;
    text-align:center;
    padding:50px 0px;
    font-family: 'Spartan', Helvetica, Arial, sans-serif;
  }
  #emailForm{
    text-align:left;
    textarea{
      height:150px;
      font-family: 'Spartan', Helvetica, Arial, sans-serif;
    }
  }
  .input_container{
    label{
      strong{
        font-family: 'Bebas Neue', Helvetica, Arial, sans-serif;
        display:block;
        margin-bottom:10px;
        font-size:1.4em;
      }
      input{
        font-size:1em;
        padding:10px;
        width:300px;
        display: block;
        margin-bottom: 30px;
        font-family: 'Spartan', Helvetica, Arial, sans-serif;
      }
      textarea{
        font-size:1em;
        padding:10px;
        width:500px;
      }
    }
  }
  .submit_button_container{
    margin-top:50px;
  }
  #directEmail{
    font-family: 'Spartan', Helvetica, Arial, sans-serif;
    margin-top:50px;
    strong{
      display:block;
      margin-bottom:30px;
    }
  }
}

@media screen and (max-width:750px){
  #contactPage{
    .input_container{
      label{
        input{
          width:100%;
        }
        textarea{
          width:100%;
        }
      }
    }
  }
}