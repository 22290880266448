@import "../../assets/styles/variables.scss";

/* Position and sizing of clickable cross button */
.bm-cross-button {


}

/* Color/shape of close button cross */
.bm-cross {
  background: $highlight;
}

.bm-burger-button{
  display:none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width:70vmin !important;
  font-family: $headline;
  // Menu being controlled from my own button
}

/* General sidebar styles */
.bm-menu {
  background: $darkBackground;
  padding: 2.5em 1.5em 0;
  width:100%;
  padding:0px;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill:  $darkBackground;
}

/* Wrapper for item list */
.bm-item-list {
  color: $highlight;
  padding: 0px;

  a{
    border-bottom: solid 1px $mediumText !important;
    color: $highlight;
    text-decoration:none;
    line-height: 7vmin;
    font-size: 4.5vmin;
    &.active{
      background-color: $mediumBackground;
    }
    &:hover{
      color: $reversedText;
      background-color: $mediumBackground;
    }
    &:focus{
      outline: none;
    }
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 10px 10px 10px 20px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}