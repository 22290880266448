@import "../../../../assets/styles/variables.scss";

.loginModal-modal, .signupModal-modal, .newPasswordModal-modal{
  .MuiDialogTitle-root{
    padding-bottom:0px;
    h2{
      margin-top:10px;
      font-family: 'Bebas Neue', Helvetica, Arial, sans-serif;
      font-size: 2em;
    }
  }
  .MuiDialogContent-root{
    z-index:11;
  }
  .MuiDialogActions-root{
    z-index:10;
   
  }
  .MuiInputBase-root{
    width:100%;
  }
  #modalBackground {
    background-repeat:no-repeat !important;
    background-position: cover;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width:100%;
    height:100%;
    position: absolute;
    z-index: 1;
  }
  &.loginModal-modal{
    .MuiPaper-root{
      #modalBackground {
        background : url('../../../../assets/images/avatars/compost_creature/compost_creature_head.webp');
        background-position: -60% 160%;
      }
      div{
        //z-index:10;
      }
    }
  }
  &.signupModal-modal{
    .MuiPaper-root{
      #modalBackground {
        background : url('../../../../assets/images/avatars/repete/repete.webp');
        background-position: -120% bottom;
        opacity:.1;
        transform: scaleX(-1);
        z-index:0;
      }
      div{
        z-index:10;
      }
    }
  }
  &.newPasswordModal-modal{
    .MuiPaper-root{
      #modalBackground {
        background : url('../../../../assets/images/avatars/chrono_guy/chrono_guy.webp');
        background-position: 180% 120%;
        opacity:.1;
      }
      div{
        z-index:10;
      }
    }
  }
  &.loginModal-modal{
    .MuiPaper-root{
      #modalBackground {
      }
    }
  }
}

.auth-form{
  display: flex;
  flex-direction: column;
  align-items: center;
  .input-container-wrapper{
    width: 100%;
    .input-container{
      margin-left: auto;
      margin-right: auto;
    }
  }
  .input-container{
    width: 60%;
    &#signupEmailOptIn{
      display:flex;
      flex-direction:row-reverse;
      span{
        font-size: .8em;
      }
    }
  }
  button.block{
    margin: 15px 0px 30px;
  }
  #forgotPassword{
    text-align:center;
    margin-top:1em;
    font-size:.8em;
  }
  #helperText{
    margin-bottom:1.7em;
    text-align:center;
  }
  #loginButton{
    margin-top:1.7em;
  }
}

@media screen and (max-width:501px){
  #modalBackground {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .loginModal-modal{
    .MuiPaper-root{
      #modalBackground {
        background-position: 60% 120% !important;
      }

    }
  }
  .auth-form{
    .input-container{
      width: 100%;
    }
    strong{
      font-size:1em;
    }
  }
}