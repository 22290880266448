@import '../../assets/styles/variables.scss';

#itemsPage{
  #tableContainer{
    width:100%;
    overflow-x: scroll;
    table{
      td.item-image{
        img{
          width : 100px;
          cursor: pointer;
        }
      }
      td.item-lore p{
        text-align: left;
        font-size: .9em;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }
      tr.divider-row{
        background-color: $darkBackground;
        h2{
          color: $reversedTextAlt;
        }
      }
      tr:hover{
        cursor: pointer;
      }
    }
  }
}