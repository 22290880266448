@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Rochester&family=Bebas+Neue&family=Pathway+Gothic+One&family=Pragati+Narrow&family=Spartan:wght@100&;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Faustina&family=Teko:wght@500;700&display=swap");

body {
 margin: 0;
 font-family: "Spartan", Helvetica, Arial, sans-serif;
 -webkit-font-smoothing: antialiased;
 -moz-osx-font-smoothing: grayscale;
 background-color: $primaryBackground;
 font-size: 1em;

 background-repeat: no-repeat;

 #root {
  margin: 0px auto;
  //max-width: 1000px;
 }
 .App {
  background-color: $primaryBackground;
 }
 header {
  position: relative;
 }
 .page {
  min-height: calc(100vh - 280px);
 }
 .settings-button {
  z-index: 1000;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 8vmin;
  color: $highlight;
  cursor: pointer;
  &:hover {
   color: $reversedTextAlt;
  }
 }
 #versionNumber {
  z-index: 10;
  position: absolute;
  right: 20px;
  top: 100px;
  font-size: calc(6px + 1vmin);
  color: $reversedText;
  width: fit-content;
 }

 a,
 button,
 input,
 div {
  &.MuiButtonBase-root,
  .MuiInputBase-root {
   font-family: "Pragati Narrow";
   &.MuiButton-containedPrimary,
   &.submit-button {
    font-size: 1.1em;
    color: $reversedText;
    background-color: $primaryButton;
    font-weight: bold;
    text-transform: uppercase !important;
    border-color: $darkBackground;
    border-radius: 4px;
    padding: 6px 16px;
    &:hover {
     background-color: $highlight;
     color: $mediumText;
    }
    &:focus {
     outline: none !important;
    }
   }
   &.MuiButton-containedSecondary {
    color: $mediumText;
    background-color: $secondaryButton !important;
    font-weight: bold;
    text-transform: uppercase;
    border-color: $darkBackground;
    &:hover {
     background-color: $highlight !important;
    }
   }
   &.MuiButton-containedHighlight,
   &.submit-button.highlight {
    color: $mediumText;
    background-color: $highlight;
    font-weight: bold;
    text-transform: uppercase;
    border-color: $darkBackground;
    &:hover {
     background-color: $mediumText;
     background-color: $secondaryBackground;
    }
   }
   &.Mui-disabled {
    color: #555555 !important;
    background-color: #333533 !important;
   }
  }
  &.block {
   width: 100%;
  }
 }

 .background-parallax {
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
 }

 .solar_celeste_hex {
  background-image: url("../../assets/images/avatars/solar_celeste/solar_celeste_hex.webp");
 }
 .timber_terror_hex {
  background-image: url("../../assets/images/avatars/timber_terror/timber_terror_hex.webp");
 }
 .repete_hex {
  background-image: url("../../assets/images/avatars/repete/repete_hex.webp");
 }
 .filtron_five_hex {
  background-image: url("../../assets/images/avatars/filtron_five/filtron_five_hex.webp");
  right: -550px;
  transform: initial;
 }
 .wilhelm_the_wild_hex {
  background-image: url("../../assets/images/avatars/wilhelm_the_wild/wilhelm_the_wild_hex.webp");
  right: 0px;
  transform: initial;
 }
 .natural_ninja_hex {
  background-image: url("../../assets/images/avatars/natural_ninja/natural_ninja_hex.webp");
  right: -550px;
  transform: initial;
 }
 .boulder_bro_hex {
  background-image: url("../../assets/images/avatars/boulder_bro/boulder_bro_hex.webp");
 }
 .empath_aurelia_hex {
  background-image: url("../../assets/images/avatars/empath_aurelia/empath_aurelia_hex.webp");
 }
 .chrono_guy_hex {
  background-image: url("../../assets/images/avatars/chrono_guy/chrono_guy_hex.webp");
  right: 0px;
  transform: initial;
 }
 .compost_creature_hex {
  background-image: url("../../assets/images/avatars/compost_creature/compost_creature_hex.webp");
  transform: initial;
  right: -550px;
 }
}

.table {
 width: 100%;
 border-collapse: collapse;
 font-family: "Pathway Gothic One", sans-serif;
 margin-bottom: 0px;
 th {
  font-weight: normal;
  opacity: 0.6;
  text-align: center;
  vertical-align: middle;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  cursor: pointer;
 }
 td {
  vertical-align: middle;
  text-align: center;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  color: rgb(51, 53, 51) !important;
 }
 .no-wrap {
  white-space: nowrap;
 }
 .activity-icon-wrap {
  font-size: 2em;
  display: inline-block;
  width: 100%;
  text-align: center;
 }

 &.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075) !important;
 }
 &.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
 }

 &.table-dark {
  background-color: #343a40;
  td,
  th {
   border: 1px solid #454d55;
   color: $reversedTextAlt;
   cursor: default !important;
  }
  color: $reversedTextAlt;
  tr {
   background-color: #333533;
  }
  svg path {
   stroke: $reversedTextAlt;
  }
  &.table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(255, 255, 255, 0.05);
  }
  &.table-striped tbody tr:nth-of-type(odd) {
   background-color: rgba(255, 255, 255, 0.05);
  }
  &.table-bordered {
   border: 0;
  }
 }
}

.hide {
 display: none;
}

.highlight {
 color: $highlight;
}
.brand-highlight {
 color: $brandHighlight;
}
.background-highlight {
 background-color: $highlight;
}
.warning {
 color: $warningText;
}
.caution {
 color: $cautionText;
}
.success {
 color: $successText;
}
.disabled {
 color: $disabledText;
}
.muted {
 opacity: 0.3;
}
.strike-through {
 text-decoration: line-through;
}
.bold {
 font-weight: bold;
}
.link-text {
 color: $linkText;
 cursor: pointer;
 &:hover {
  color: $highlight;
 }
}
.light-link-text {
 color: $reversedLinkText;
 cursor: pointer;
 &:hover {
  color: $highlight;
 }
}
.strava-highlight {
 color: #f54c05;
}
.fire-highlight {
 color: #e25822;
}
.earth-highlight {
 color: #8a360f;
}
.water-highlight {
 color: #0f5e9c;
}
.air-highlight {
 color: #16a0f5;
}
.aether-highlight {
 color: #ffffc2;
}
.timber-terror-theme {
 color: #3d2a18;
}
.repete-theme {
 color: #9b9b9b;
}
.filtron-five-theme {
 color: #ebebeb;
}
.chorno-guy-theme {
 color: #4b4b4b;
}
.solar-warrior-theme {
 color: #ebdd49;
}
.wildspeaker-theme {
 color: #533b27;
}
.natural-ninja-theme {
 color: #0c2613;
}
.empath-theme {
 color: #b9af73;
}
.boulder-bro-theme {
 color: #986634;
}
.compost-creature-theme {
 color: #796d20;
}
.display-linebreak {
 white-space: pre-line;
}
.draggable {
 display: flex;
 flex-direction: row;
 justify-content: flex-start;
 align-items: stretch;
 flex: 1;
 text-align: left;
 background-color: $primaryBackground;
 border: solid 1px $mediumBorder;
 color: $mediumText;
 font-size: 0.8em;
 vertical-align: center;
 &.focus {
  border: none;
 }
 .draggable-inline-col {
  &.draggable-icon {
   text-align: center;
   width: 1.5em;
   padding: 5px;
   background-color: $secondaryBackground;
   font-size: 1.5em;
   line-height: 1.5em;
  }
  &.draggable-index {
   font-family: "Pathway Gothic One", sans-serif;
   text-align: center;
   padding: 5px;
   width: 1.5em;
   line-height: 1.5em;
   font-size: 1.2em;
   font-weight: bold;
   background-color: $highlight;
  }
  &.draggable-description {
   padding: 10px;
   font-size: 1.2em;
   line-height: 1.4em;
   width: 85%;
  }
 }
}

.input-container {
 margin-bottom: 1.7em;
}

input[type="submit"] {
 cursor: pointer;
 text-transform: uppercase;
 font-size: 1.1em;
 &.Mui-disabled {
  &:hover {
   cursor: default;
  }
 }
}

input[type="text"],
input[type="email"],
input[type="password"] {
 font-size: 1.2em;
}

.MuiDialog-root {
 .MuiDialog-container {
  .MuiDialog-paper,
  .MuiDialog-paperWidthSm {
   width: 600px;
   .MuiDialogTitle-root {
    padding: 16px 24px 4px;
    .MuiTypography-h6 {
     line-height: 1em;
    }
   }
  }
 }
}

.page-loading-indicator {
 width: 30%;
 margin: 0px auto;
 padding: 3em;
 .loading-message {
  font-family: "Pragati Narrow";
  font-size: 2em;
  font-weight: bold;
  color: $lightText;
  text-align: center;
  margin-top: 2em;
 }
}

// Home page, don't display footer, but we want to still display alerts that launch from footer
#home {
 & + footer {
  border-top: none;
  height: initial;
  #footerContent {
   display: none;
  }
 }
}

.foe-Shade,
.foe-shade,
.skin-shade,
.opacity-100 {
 filter: opacity(100%);
}
.foe-Wraith,
.foe-wraith,
.skin-wraith,
.opacity-90 {
 filter: opacity(90%);
}
.foe-Phantasm,
.foe-phantasm,
.skin-phantasm,
.opacity-80 {
 filter: opacity(80%);
}
.foe-Banshee,
.foe-banshee,
.skin-banshee,
.opacity-70 {
 filter: opacity(70%);
}
.foe-Phantom,
.foe-phantom,
.skin-phantom,
.opacity-60 {
 filter: opacity(60%);
}
.foe-Specter,
.foe-specter,
.skin-specter,
.opacity-50 {
 filter: opacity(50%);
}
.foe-Poltergeist,
.foe-poltergeist,
.skin-poltergeist,
.opacity-40 {
 filter: opacity(40%);
}
.foe-Apparition,
.foe-apparition,
.skin-apparition,
.opacity-30 {
 filter: opacity(30%);
}

.skin-shade {
 filter: grayscale(100%) opacity(100%) brightness(60%);
}
.skin-wraith {
 filter: grayscale(90%) opacity(90%) brightness(60%) contrast(145%);
}
.skin-phantasm {
 filter: grayscale(90%) opacity(80%) brightness(70%);
}
.skin-banshee {
 filter: grayscale(80%) opacity(70%) brightness(70%) contrast(150%);
}
.skin-phantom {
 filter: grayscale(80%) opacity(60%) brightness(80%);
}
.skin-specter {
 filter: grayscale(70%) opacity(50%) brightness(80%) contrast(145%);
}
.skin-poltergeist {
 filter: grayscale(60%) opacity(40%) brightness(90%) saturate(200%);
}
.skin-apparition {
 filter: grayscale(40%) opacity(20%) brightness(100%);
}

.infected {
 filter: sepia(100%) saturate(700%) contrast(125%) brightness(80%) hue-rotate(90deg);
 //filter: contrast(130%) saturate(200%) hue-rotate(90deg);
}
.knocked-out {
 filter: grayscale(100%) brightness(30%);
}

.fire-tint,
.skin-fire-tint {
 filter: sepia(100%) saturate(600%) brightness(70%) hue-rotate(-20deg);
}
.earth-tint,
.skin-earth-tint {
 filter: sepia(100%) saturate(600%) brightness(30%) hue-rotate(0deg);
}
.water-tint,
.skin-water-tint {
 filter: sepia(100%) saturate(600%) brightness(70%) hue-rotate(180deg);
}
.air-tint,
.skin-air-tint {
 filter: sepia(100%) saturate(400%) brightness(120%) hue-rotate(180deg);
}

.title-white-belt {
 color: #f8f8ff !important;
}
.title-yellow-belt {
 color: rgb(245, 245, 64) !important;
}
.title-orange-belt {
 color: rgb(245, 174, 42) !important;
}
.title-blue-belt {
 color: rgb(63, 63, 205) !important;
}
.title-purple-belt {
 color: purple !important;
}
.title-green-belt {
 color: rgb(35, 153, 35) !important;
}
.title-red-belt {
 color: rgb(194, 25, 25) !important;
}
.title-brown-belt {
 color: rgb(129, 17, 17) !important;
}
.title-black-belt,
.title-black-belt-sensei {
 color: black !important;
}
.royal-purple {
 color: #7851a9;
}
.clearfix {
 overflow: auto;
}
.clearfix::after {
 content: "";
 clear: both;
 display: table;
}

@media screen and (min-width: 1025px) {
 body .settings-button {
  font-size: 4vh;
 }
}

@media screen and (max-width: 599px) {
 .MuiDialog-root {
  .MuiDialog-container {
   .MuiDialog-paper,
   .MuiDialog-paperWidthSm {
    margin: 0px;
    max-width: 100%;
    .MuiDialogTitle-root {
     padding: 8px 12px;
    }
    .MuiDialogContent-root {
     padding: 8px 12px;

     .column {
      display: block;
      flex: initial !important;
      width: initial !important;
      &:nth-of-type(1) {
       padding: 0px 10px !important;
      }
      &:nth-of-type(2) {
       padding: 0px 10px !important;
      }
     }
     .MuiDialogContentText-root {
      margin-bottom: 0.5em;
     }
    }
   }
  }
  .MuiInputBase-root {
   padding: 5px;
   &.MuiInput-fullWidth {
    width: 100%;
   }
  }
 }
}
